<template>
  <ul v-if='list.length > 0' class="hori-list home-side-list">
    <li @click='selectSide(side)' v-for='(side, index) in list' :key='index'>

      <!-- NOTE EMPLOYER INFO -->

        <img v-if='side.employerId.profileImage.medium' :src='side.employerId.profileImage.medium' />

        <div class="info-cont">
          <h3 class='title'>{{ side.employerId.name || `${side.employerId.firstname} ${side.employerId.lastname}` }}</h3>
          <h4 class='title'>{{ side.job.industry }}</h4>
          <div class='location-label'>
            <FontAwesomeIcon class='marker-icon' icon='map-marker-alt'></FontAwesomeIcon>
            <h5>{{ side.job.location.civicLocation.label }}</h5>
          </div>
        </div>

    </li>
  </ul>

  <h5 v-else class='sml-para'>{{ $t('no content') }}</h5>
</template>

<script>
import { fetchSideList } from '@/apiCall/sides.js';

import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'SidesList',
  data(){
    return {
      list: [],
    };
  },
  props: {
    currentUser: Object,
    listType: String,
    categories: {
      type: Array,
      default: ()=>{
        return [];
      }
    }
  },
  computed: {
    ...mapGetters({
      location: 'location/userLocation'
    })
  },
  methods: {
    selectSide(side){
      this.setModal({
        type: 'sideVisualizer',
        side: side
      })
    },
    async getSideList(){
      try {

        let { latitude, longitude } = this.location.data;
        let data = {
          lat: latitude,
          long: longitude,
          radius: this.currentUser.user.radiusOfAction
        }
        let list = [];
        switch (this.listType) {
          case 'near':
          data.type = 'near';
          list = await fetchSideList(this.currentUser.user._id, data)
          break;

          // case 'near':
          // data.type = 'near';
          // list = await fetchSideList(this.currentUser.user._id, data)
          // break;
        }

        this.list = list;

      } catch (e) {
        console.log(e)
      }
    },
    ...mapActions({
      setModal: 'modal/setModal'
    })
  },
  mounted(){
    // if(this.location.loaded===true){
    console.log('location loaded',this.location.loaded)
    console.log('is authenticated',this.currentUser.isAuthenticated)
      this.getSideList()
    // }
  }
}
</script>
