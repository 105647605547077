import * as Vibrant from "node-vibrant";
import { toURL } from "./index.js";

export const getVibrant = async ref => {
  let url = toURL(ref);

  let v = await new Vibrant(url);
  // let v = await new Vibrant(URL.createObjectURL(transformedFile));
  let vibrantPalette = {};

  try {
    let palette = await v.getPalette();

    Object.keys(palette).map(key => {
      let cat = palette[key];
      vibrantPalette[key] = inlineVibrantFormatter(cat.rgb);
      // vibrantPalette[key] = cat.rgb;
    });

    return vibrantPalette;

  } catch (err) { // NOTE IF ERROR => CREATE MOCK VIBRANT (ALL WHITE)
    console.log(err);
    return {
      "Vibrant": inlineVibrantFormatter([255,255,255]),
      "LightVibrant": inlineVibrantFormatter([186,186,186]),
      "DarkVibrant": inlineVibrantFormatter([127,127,127]),
      "Muted": inlineVibrantFormatter([76,76,76]),
      "LightMuted": inlineVibrantFormatter([234,234,234]),
      "DarkMuted": inlineVibrantFormatter([36,36,36])
    }
  }
};

export const getVibrantFromUrl = async url => {
  // let url = toURL(ref);

  let v = await new Vibrant(url);
  // let v = await new Vibrant(URL.createObjectURL(transformedFile));
  let vibrantPalette = {};

  try {
    let palette = await v.getPalette();

    Object.keys(palette).map(key => {
      let cat = palette[key];
      vibrantPalette[key] = inlineVibrantFormatter(cat.rgb);
      // vibrantPalette[key] = cat.rgb;
    });

    return vibrantPalette;

  } catch (err) { // NOTE IF ERROR => CREATE MOCK VIBRANT (ALL WHITE)
    console.log(err);
    return {
      "Vibrant": inlineVibrantFormatter([255,255,255]),
      "LightVibrant": inlineVibrantFormatter([186,186,186]),
      "DarkVibrant": inlineVibrantFormatter([127,127,127]),
      "Muted": inlineVibrantFormatter([76,76,76]),
      "LightMuted": inlineVibrantFormatter([234,234,234]),
      "DarkMuted": inlineVibrantFormatter([36,36,36])
    }
  }
};

// reformat vibrant color ready to use
const inlineVibrantFormatter = vibrant => {
  return `rgb(${vibrant[0]},${vibrant[1]},${vibrant[2]})`;
};

// reformat vibrant color ready to use
export const vibrantFormatter = vibrant => {
  if(vibrant.includes('rgb')){
    return vibrant;

  } else {
    return `rgb(${vibrant[0]},${vibrant[1]},${vibrant[2]})`;
  }
};

// reformat vibrant color ready to use
export const vibrantPaletteFormatter = v => {
  let vibrant = {};
  for (var x in v) {
    if ({}.hasOwnProperty.call(v, x)) {
      let vib = v[x];
      vibrant[x] = `rgb(${vib[0]},${vib[1]},${vib[2]})`;
    }
  }
  return vibrant;
};




// reformat vibrant color ready to use
export const colorToRgbA = (color, opacity) => {

  if(color[0]==='#'){ // NOTE: IF HEX
    var c;
    if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(color)){
      c= color.substring(1).split('');
      if(c.length== 3){
        c= [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c= '0x'+c.join('');
      // return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',1)';
      return `rgba(${[(c>>16)&255, (c>>8)&255, c&255].join(',')},${opacity})`;
    }
    throw new Error('Bad Hex');

  } else if(color.substring(0, 4)==='rgb(') {
    return color.replace(')', `,${opacity})`);

  } else if(color.substring(0, 5)==='rgba(') {
    return color;
  }
};
