import { apiCall } from "../services/api.js";

// GET ADDRESS AUTOCOMPLETION FROM BACKEND TO API
export const autoComplete = async data => {
  return apiCall("post", `/api/data/autocomplete`, data);
};

// GEOCODE FROM ADDRESS LABEL
export const getAddressFromCoords = async data => {
  return apiCall("post", `/api/data/geocode`, data);
};

// // GET GEOLOCATION DETAILS
export const getGeolocation = async (data) => {
  let { latitude, longitude } = data;
  return apiCall(
    "get",
    `/api/data/geolocation?lat=${latitude}&long=${longitude}`
  );
};
//
// // GEOCODE FROM ADDRESS LABEL
// export const geoCode = async data => {
//   return apiCall("post", `/user/new/data/geocode`, data);
// };

export const searchForSidesByKeywords = async (userId, search, lat, long) => {
  return apiCall("get", `/api/data/search-keywords?search=${search}&lat=${lat}&long=${long}`);
};
