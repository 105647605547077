<template lang="html">
  <div class="prospect-match">

    <ul v-if='list.length > 0' class='prospect-list'>
      <li v-for='(prospect, index) in list' :key='index'>

        <div class="line">
          <h5>{{ prospect.side.job.name }}</h5>
          <h5>{{ parseDate(prospect.side.date) }}</h5>
        </div>

        <div class="line">
          <div class="user-label">
            <img :src='prospect.staff.profileImage.small' />
            <h5>{{ prospect.staff.name || `${prospect.staff.firstname} ${prospect.staff.lastname}` }}</h5>
          </div>

          <div class="rating-label">
            <FontAwesomeIcon icon='star'></FontAwesomeIcon>
            <h4>{{ prospect.staff.staffRating.average }}</h4>
          </div>
        </div>

        <h5>{{ prospect.staff.phone }}</h5>

        <p>{{ prospect.staff.aboutMe}}</p>

        <button @click='seeStaff(prospect)'>{{ $t('see more') }}</button>

        <div class="line">
          <!-- <button @click='messageUser(prospect.staff)'>{{ $t('message') }}</button> -->
          <button class='cancel' @click='cancelStaff(prospect, "reject")'>{{ $t('cancel staff') }}!</button>
          <button class='confirm' @click='rateStaff(prospect)'><FontAwesomeIcon icon='star'></FontAwesomeIcon>{{ $t('rate') }}!</button>
        </div>
      </li>
    </ul>

    <h5 v-else class='sml-para'>{{ $t('No prospect pending') }}</h5>
  </div>
</template>

<script>
import * as dayjs from 'dayjs';

import { getEmployerSideApplicationList, cancelSideProspect } from '@/apiCall/sides.js';

import { mapActions } from 'vuex';

export default {
  name: 'ConfirmedMatches',
  data(){
    return {
      list: [],
      selectedApplication: null,
      decisionMessage: ''
    };
  },
  props: {
    currentUser: Object,
    setAlert: Function
  },
  methods: {
    parseDate(date){
      return dayjs(date).format('LL');
    },
    selectPage(page){
      this.page = page
    },
    async fetchMatches(){
      try {

        this.list = await getEmployerSideApplicationList(this.currentUser.user._id, 'accepted')

      } catch (e) {
        console.log(e)
      }
    },
    messageUser(user){
      console.log(user)
    },
    rateStaff(side){
      this.setModal({
        type: 'rateStaff',
        side
      })
    },
    async cancelStaff(side){
      try {

        let data = {
          sideApplicationId: side._id,
          message: this.decisionMessage
        }

          await cancelSideProspect(this.currentUser.user._id, data)

        this.setAlert({
          type: 'info',
          data: {
            status: 'success',
            message: this.$t('Operation successfull')
          }
        })

        this.fetchMatches();

      } catch (e) {
        console.log(e)
      }
    },
    ...mapActions({
      setModal: 'modal/setModal'
    })
  },
  mounted(){
    this.fetchMatches();
  }
}
</script>
