<template>
  <ion-page>
  <ion-content>
    <div class="landing">
      <!-- <img src='https://onthesideapp.s3.amazonaws.com/ressources/logo/Group+1342.png' /> -->
      <p>AppMap</p>
    </div>
  </ion-content>
</ion-page>
</template>

<script>
import { IonPage, IonContent } from '@ionic/vue';

export default {
  name: 'AppMap',
  data(){
    return {

    };
  },
  components: {
    IonPage,
    IonContent
  },
}
</script>
