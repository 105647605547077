const DEFAUT_STATE = {
  type: null,
  data: null,
  error: null
};

export const modal = {
  namespaced: true,
  state() {
    return DEFAUT_STATE;
  },
  getters: {
    getModal(state) {
      return state;
    },
    getModalType(state) {
      return state.type;
    }
  },
  mutations: {
    SET_MODAL(state, data) {
      let { type, ...filteredData } = data;
      state.type = type;
      state.data = { ...filteredData };
    },
    CLOSE_MODAL(state) {
      state.type = null;
      state.data = null;
      state.error = null;
    }
  },
  actions: {
    setModal({ commit }, data) {
      commit("SET_MODAL", data);
    },
    resetModal({ commit }) {
      commit("CLOSE_MODAL");
    }
  }
};
