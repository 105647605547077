<template>
  <div class="user-menu-modal">

    <ul v-if='list.length > 0'>
      <li v-for='(side, index) in list' :key='index' @click='selectSide(side.id)'>
        <h4>{{ side.date }}</h4>
        <div class="" :class='{selected: selectedSide===side.id}'>

        </div>
      </li>
    </ul>

    <h5 v-else>{{ $t('No jobs created yet') }}</h5>

    <!-- <div class='create-job-btn' @click='toggleCreator'>
      <FontAwesomeIcon icon='plus'></FontAwesomeIcon>
      <h4>{{ $t('job') }}</h4>
    </div> -->

    <transition appear name="very-short" mode="out-in">
      <div v-if='showCreator===true'>
        <h2>{{ $t('Create a side')}}</h2>

        <form @submit.prevent>
          <input type='datetime' v-model='newjob.date' />
          <input type="type" v-model='newjob.location' />
          <textarea v-model='newjob.details'></textarea>

          <button @click='createSide'>{{ $t('send') }}</button>

        </form>
      </div>
    </transition>


  </div>
</template>

<script>
import { createAside, getSideList } from '@/apiCall/sides.js'

export default {
  name: 'UserMenuCompletedList',
  data(){
    return{
      list: [],
      selectedSide: null,
      //
      showCreator: false,
      newjob: {
        date: '',
        location: '',
        jobId: null,
        details: '',
      }
    };
  },
  props: {
    currentUser: Object
  },
  methods: {
    toggleCreator(){
      this.showCreator = !this.showCreator;
    },
    async fetchSide(){
      try {

        this.list = await getSideList(this.currentUser.user._id)

      } catch (e) {
        console.log(e)
      }
    },
    async createSide(){
      try {

        let data = this.newjob;

        await createAside(this.currentUser.user._id,data)

      } catch (e) {
        console.log(e)
      }
    },
    selectSide(id){
      this.selectedSide = id;
    }
  },
  mounted(){
    this.fetchSide()
  }
}
</script>
