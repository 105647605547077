<template>
  <ion-app>
    <AppNavbar></AppNavbar>
    <ion-router-outlet />
    <transition appear name="very-short" mode="out-in">
      <UserMenu v-if='showUserMenu===true&&isAuthenticated===true' :currentUser='currentUser'></UserMenu>
    </transition>
    <BottomBar v-if='currentUser.isAuthenticated===true' :currentUser='currentUser'></BottomBar>
    <AppModal />
    <AppAlert />

  </ion-app>
</template>

<script>
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { defineComponent } from 'vue';

import AppAlert from '@/components/modal/alerts/AppAlert.vue'
import AppNavbar from '@/components/nav/AppNavbar.vue'
import AppModal from '@/components/modal/AppModal.vue'
import BottomBar from '@/components/nav/BottomBar.vue'
import UserMenu from '@/components/userMenu/UserMenu.vue';

import { Capacitor } from "@capacitor/core";
// import { SplashScreen } from "@capacitor/splash-screen";
import { getPlatforms } from "@ionic/vue";
// import { ScreenOrientation } from "@awesome-cordova-plugins/screen-orientation"; // https://github.com/apache/cordova-plugin-screen-orientation


import * as dayjs from "dayjs";
require("dayjs/locale/fr");
require("dayjs/locale/es");

import { mapActions, mapGetters } from 'vuex';

export default defineComponent({
  name: 'App',
  components: {
    AppAlert,
    AppNavbar,
    BottomBar,
    IonApp,
    IonRouterOutlet,
    AppModal,
    UserMenu
  },
  computed: {
    isAuthenticated(){
      return this.currentUser.isAuthenticated===true;
    },
    isLocationLoaded(){
      return this.location.loaded===true;
    },
    showUserMenu(){
      return this.getUserMenu.show===true;
    },
    ...mapGetters({
      currentUser: 'user/currentUser',
      getUserMenu: 'userMenu/getUserMenu',
      location: 'location/userLocation'
    })
  },
  methods: {
    async sessionRecovery() {

      if (localStorage.onTheSide) {

        console.log('hellllllooooo')

        this.recoverSession();

      }
      // else {
      //   await SplashScreen.hide();
      // }
    },
    setAppHeight(){
      // const documentHeight = () => {
      const doc = document.documentElement
       doc.style.setProperty('--doc-height', `${window.innerHeight}px`)
      // }
      window.addEventListener('resize', this.setAppHeight)
      // documentHeight()
    },
    setLanguage() {

      let lng;
      if(this.isAuthenticated===false){
        lng = navigator.language;
        this.$i18n.locale = lng.substring(0, 2);
      } else {
        lng = this.currentUser.user.prefLanguage;
        this.$i18n.locale = lng;
      }
      dayjs.locale(lng);
    },
    setCurrentPlateform() {
      let data = {
        isMobile: false,
        plateform: Capacitor.getPlatform(),
        deviceType: getPlatforms(),
        screenType: "",
        contentSize: "",
        deviceSize: {
          width: screen.width,
          height: screen.height
        }
      };

      if (data.deviceType.indexOf("mobile") !== -1) {
        data.isMobile = true;
        // ScreenOrientation.lock("portrait");
      }

      // if(data.plateform === 'web'){
        this.setAppHeight();
      // }

      let screenType = "small";
      let contentSize = "mobile";

      if (screen.width > 2600) {
        screenType = "xLarge";
        contentSize = "wideScreen";
      } else if (screen.width > 1200 && screen.width <= 2600) {
        screenType = "large";
        contentSize = "wideScreen";
      } else if (screen.width > 600 && screen.width <= 1200) {
        screenType = "medium";
        contentSize = "wideScreen";
      } else {
        screenType = "small";
        contentSize = "mobile";
      }

      data.screenType = screenType;
      data.contentSize = contentSize;

      this.setPlateform(data);

      console.log('Plateform info',data)
    },
    // async sessionRescovery() {
    //   // Check if refreshToken is present
    //   // let isLogged = getCookie('refreshToken')
    //
    //   // if token is locally stored recover session from it
    //   if (localStorage.ref_token) {
    //     // trigger session recovery from token
    //
    //     this.setTopModal({
    //       type: "creationTransition",
    //       uploadCompleted: false
    //     });
    //
    //     this.recoverSession();
    //     window.beforeunload = this.beforeToGo();
    //   } else {
    //     // this.$router.push(`/`);
    //     await SplashScreen.hide();
    //   }
    // },
    ...mapActions({
      setPlateform: "device/setPlateform",
      recoverSession: "recoverSession",
      getPreciseLocation: 'location/getPreciseLocation',
      logOut: "logOut",
    })
  },
  watch: {
    isLocationLoaded(val){
      if(val===true&&this.isAuthenticated===true){
        console.log(this.isAuthenticated)
        this.$router.push('/home')
      }
    },
    isAuthenticated(val, oldVal){
      if(val===true){
        this.setLanguage()
        if(this.isLocationLoaded===true){
          this.$router.push('/home')
        }
      }
      if(val===false&&oldVal===true){
        this.logOut()
        this.$router.push('/')
      }
    }
  },
  mounted(){
    // DARK THEME ALWAYS ON
    document.body.classList.add("dark");

    this.$router.push('/')

    this.setCurrentPlateform();

    this.getPreciseLocation()

    this.sessionRecovery()
  }
});
</script>

<style lang="scss">
@import "./theme/appTheme.scss";
</style>
