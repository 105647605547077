import axios from "axios";

// import {
//   Capacitor
// } from '@capacitor/core';

export let apiCall, alwaysAxiosAPICall, apiCallWithCancel, withCredentialsApiCall, setShortToken;



// import {
//   HTTP
// } from '@ionic-native/http';


export const PROD_API = 'https://api.onthesidejob.com';


// let baseURL = 'https://staging-api.goaxyo.com';
let baseURL = process.env.VUE_APP_API_BASE_URL;


const axiosInstance = axios.create({
  baseURL
  // baseURL: 'https://staging-api.goaxyo.com'
});






alwaysAxiosAPICall = async (method, path, data) => {

  return new Promise((resolve, reject) => {
    return axiosInstance[method](path, data)
      .then(res => {
        return resolve(res.data);
      })
      .catch(err => {
        return reject(err.response);
      });
  });
}



// NOTE **** FOR NATIVE ONLY ****

// if (Capacitor.isNativePlatform()) {
//
//   setShortToken = (token) => {
//     if (token) {
//       HTTP.setHeader('Authorization', `Bearer ${token}`);
//       axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
//     } else {
//       HTTP.setHeader('Authorization', null);
//       delete axiosInstance.defaults.headers.common["Authorization"];
//     }
//   }
//
//
//
//
//   apiCall = async (method, path, data) => {
//
//     const options = {
//       method,
//       serializer: 'json',
//       responseType: 'json'
//       // headers: { Authorization: `Bearer ${token}` }
//     };
//
//     if (method === 'post') {
//       options.data = data;
//
//     } else if (method === 'put') {
//       options.data = data;
//
//     } else if (method === 'get') {
//       options.params = data;
//     }
//
//
//     return new Promise((resolve, reject) => {
//       return HTTP.sendRequest(`${baseURL}${encodeURI(path)}`, options, function(response) {
//           // NOTE SUCCESS
//             console.log(path, response.data);
//             return resolve(response.data);
//
//         }, function(response) { // NOTE: ERROR
//           console.log('error', path, response.error);
//           return reject(response.error);
//         });
//     });
//   }
//
//   // customCall = async (url, method, path, data) => {
//   //
//   //   const options = {
//   //     method,
//   //     serializer: 'json',
//   //     responseType: 'json'
//   //     // headers: { Authorization: `Bearer ${token}` }
//   //   };
//   //
//   //   if (method === 'post') {
//   //     options.data = data;
//   //
//   //   } else if (method === 'put') {
//   //     options.data = data;
//   //
//   //   } else if (method === 'get') {
//   //     options.params = data;
//   //   }
//   //
//   //
//   //   return new Promise((resolve, reject) => {
//   //     return HTTP.sendRequest(`${url}${encodeURI(path)}`, options, function(response) {
//   //         // NOTE SUCCESS
//   //           console.log(path, response.data);
//   //           return resolve(response.data);
//   //
//   //       }, function(response) { // NOTE: ERROR
//   //         console.log('error', path, response.error);
//   //         return reject(response.error);
//   //       });
//   //   });
//   // }
//
//
//
//
//   withCredentialsApiCall = async (method, path, data) => {
//
//     const options = {
//       method,
//       serializer: 'json',
//       responseType: 'json',
//       webFetchExtra: {
//         credentials: "include",
//       }
//       // headers: {
//       // }
//       // headers: { Authorization: `Bearer ${token}` }
//     };
//
//     if (method === 'post') {
//       options.data = data;
//
//     } else if (method === 'put') {
//       options.data = data;
//
//     } else if (method === 'get') {
//       options.params = data;
//     }
//
//
//     // return new Promise((resolve, reject) => {
//     //   return HTTP.sendRequest(`${baseURL}${encodeURI(path)}`, options, function(response) {
//     //       // NOTE SUCCESS
//     //         console.log(path, response.data);
//     //         return resolve(response.data);
//     //
//     //     }, function(response) { // NOTE: ERROR
//     //       console.log('error', path, response.error);
//     //       return reject(response.error);
//     //     });
//     // });
//
//     return new Promise((resolve, reject) => {
//       return HTTP.sendRequest(`${baseURL}${encodeURI(path)}`, options, function(response) {
//           // NOTE SUCCESS
//             console.log(path, response.data);
//             return resolve(response.data);
//
//         }, function(response) { // NOTE: ERROR
//           console.log('error', path, response.error);
//           return reject(response.error);
//         });
//     });
//
//   }
//
//
//
//
//
//
//
//
//   // let handleCookies = async (path) => {
//   //
//   //   // let options = {}
//   //
//   //   return new Promise((resolve, reject) => {
//   //     return HTTP.getCookies(`${baseURL}${path}`, (err,cookies) => {
//   //     // return HTTP.getCookies(`${baseURL}${path}`, options, (err,cookies) => {
//   //       if(err){
//   //         console.log(err)
//   //         return reject(err)
//   //       } else {
//   //         console.log(cookies)
//   //         // HTTP.setCookie(url, cookie, options);
//   //         return resolve()
//   //       }
//   //     });
//   //   });
//   // }
//
//   // withCredentialsApiCall = async (method, path, data) => {
//   //
//   //   // let cook = await handleCookies(cookiePath);
//   //   // console.log(cook)
//   //
//   //   let { cookie, ep } = await apiCall(method, path, data)
//   //
//   //   // let cook = HTTP.getCookies();
//   //
//   //   // const setMyCookies = (cook) => {
//   //   //   // console.log('cookit',cook)
//   //   //   // HTTP.setCookie('goaxyo.com', cook);
//   //   //   HTTP.setCookie('goaxyo.com', cook, {
//   //   //     http: false,
//   //   //     secure: true,
//   //   //     now: new Date(),
//   //   //     sameSiteContext: 'none' // none lax strict
//   //   //   });
//   //   // }
//   //
//   //   const setMyCookies = (cook) => {
//   //
//   //     HTTP.setCookie('.goaxyo.com', cook, {
//   //       http: false,
//   //       secure: true,
//   //       now: new Date(),
//   //       sameSiteContext: 'none' // none lax strict
//   //     });
//   //   }
//   //
//   //   for (var x in cookie) {
//   //     if ({}.hasOwnProperty.call(cookie,x)) {
//   //       let cookieInfo = `${x}=${cookie[x]}`
//   //       console.log(x, cookieInfo)
//   //       setMyCookies(cookieInfo)
//   //     }
//   //   }
//   //
//   // // NOTE COOKIES SET IN LOCAL STORAGE BUT CANT SET IN COOKIE HEADERS
//   //
//   //   return ep;
//   //
//   // }
//
//
//
// } else { // NOTE: PLATEFORME IS WEB
//



  setShortToken = (token) => {
    if (token) {
      axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    } else {
      delete axiosInstance.defaults.headers.common["Authorization"];
    }
  }

  // const EXPIRATION = 60 * 15 * 1000;
  // let inMemoryToken = null; // eslint-disable-line no-unused-vars
  // setShortToken = async token => {
  //   // export const setShortToken = async token => {
  //   if (token) {
  //     let now = Date.now();
  //
  //     inMemoryToken = {
  //       token: token,
  //       expiry: now + EXPIRATION
  //     };
  //     setTokenHeader(token);
  //   } else {
  //     inMemoryToken = null;
  //     setTokenHeader();
  //   }
  // };


  apiCall = async (method, path, data) => {

    return new Promise((resolve, reject) => {
      return axiosInstance[method](path, data)
        .then(res => {
          return resolve(res.data);
        })
        .catch(err => {
          console.log(err.response)
          return reject(err.response);
        });
    });
  }


  apiCallWithCancel = async (method, path, data, cancelToken) => {

    return new Promise((resolve, reject) => {
      return axiosInstance.request({
        url: path,
        method,
        data,
        cancelToken
       })
        .then(res => {
          return resolve(res.data);
        })
        .catch(err => {
          return reject(err.response);
        });
    });
  }



  withCredentialsApiCall = async (method, path, data) => {
    // export function withCredentialsApiCall(method, path, data, options) {
    return new Promise((resolve, reject) => {
      return axiosInstance[method](path, data, {
          withCredentials: true
        })
        .then(res => {
          return resolve(res.data);
        })
        .catch(err => {
          return reject(err.response);
        });
    });
  }
// }
