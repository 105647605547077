import Axios from "axios";
import { apiCall } from "../../services/api";

// CALLED FROM AND RETURN DIRECTLY TO COMPONENT UPLOADER
// export const fetchS3SignedRequest = async (userId, data) => {
//   return await apiCall("post", `/api/user/${userId}/sign_s3`, data);
// };

// UPLOAD TO S3 WITH SIGNED URL
export const uploadToS3 = (
  file,
  signedRequest,
  progressCallback = () => {
    return;
  }
) => {
  console.log(signedRequest);
  console.log(file);

  const options = {
    headers: {
      "Content-Type": file.type
    }
  };

  let instance = Axios.create();
  const myInterceptor = instance.interceptors.request.use(function(config) {
    config.headers.common = { Accept: "application/json, text/plain, */*" };
    config.onUploadProgress = progressEvent =>
      progressCallback(Math.round((progressEvent.loaded / file.size) * 100));
    return config;
  });

  return instance
    .put(signedRequest, file, options)
    .then(res => {
      instance.interceptors.request.eject(myInterceptor);
      return res;
    })
    .catch(err => {
      instance.interceptors.request.eject(myInterceptor);
      throw err;
    });
};

// NOTE: LOOP OVER FILES TO UPLOAD
export const uploadMultipleFiles = async (files, signedUrls) => {
  // console.log(files)
  // console.log(signedUrls)

  // loop over files to upload content

  for (var x in files) {
    if ({}.hasOwnProperty.call(files, x)) {
      let url = signedUrls[x];
      let file = files[x];
      // console.log(file);
      // console.log(url);
      await uploadToS3(file, url);
    }
  }
};

// export const uploadMultiPart = async (userId, data, file) => {
//   let uploadInfo = await apiCall("post",`/api/user/${userId}/media/upload/multi`,data);
//
//   let { params, partList } = uploadInfo;
//
//   console.log(file, partList)
//
//   let etagList = await uploadParts(file, partList);
//
//   let info = {
//     params,
//     etagList
//   };
//
//   console.log(info)
//
//   await apiCall("post", `/api/user/${userId}/media/upload/multi/close`, info);
//
//   return "success";
// };

export const uploadMultiPart = async (
  userId,
  uploadInfo,
  file,
  progressCallback = () => {
    return;
  }
) => {
  let { params, partList } = uploadInfo;

  // console.log(file, partList);

  let etagList = await uploadParts(file, partList, progressCallback);

  let info = {
    params,
    etagList
  };

  // console.log(info);

  await apiCall("post", `/user/${userId}/data/upload/multi/close`, info);

  return "success";
};

export const uploadParts = async (file, urls, progressCallback) => {
  const FILE_CHUNK_SIZE = 10000000;

  const parts = Math.ceil(file.size / FILE_CHUNK_SIZE);
  let progression = 0;

  let partCompleted = () => {
    progression = ++progression;
    progressCallback(((progression / parts) * 100).toFixed(1));
  };

  let instance = Axios.create();
  const myInterceptor = instance.interceptors.request.use(function(config) {
    config.headers.common = { Accept: "application/json, text/plain, */*" };

    config.onUploadProgress = progressEvent => {
      // progressCallback(Math.round((progressEvent.loaded / file.size) * 100));
      if (progressEvent.loaded === FILE_CHUNK_SIZE) {
        partCompleted();
      }
    };

    return config;
  });

  const keys = Object.keys(urls);
  console.log(keys, FILE_CHUNK_SIZE);
  const promises = [];

  if(keys.length===1){
    promises.push(instance.put(urls[1], file));

  } else {

    for (const indexStr of keys) {
      console.log(indexStr);
      const index = parseInt(indexStr) - 1;
      const start = index * FILE_CHUNK_SIZE;
      const end = (index + 1) * FILE_CHUNK_SIZE;
      const blob =
      index < keys.length ? file.slice(start, end) : file.slice(start);

      console.log(urls[index + 1], blob);

      promises.push(instance.put(urls[index + 1], blob));
      // .then(res => {
      //   console.log(res.headers)
      //   instance.interceptors.request.eject(myInterceptor);
      //   return res;
      // })
      // .catch(() => {
      //   instance.interceptors.request.eject(myInterceptor);
      // }));
    }

  }

  const resParts = await Promise.all(promises);

  console.log(resParts);

  let tags = resParts.map((part, index) => {
    return {
      ETag: part.headers.etag,
      PartNumber: index + 1
    };
  });

  instance.interceptors.request.eject(myInterceptor);

  return tags;
};

export const transcodeAudio = async (userId, encodeInfo) => {
  await apiCall("post", `/user/${userId}/audio/transcode`, encodeInfo);
};

export const transcodeVideo = async (userId, encodeInfo) => {
  await apiCall("post", `/user/${userId}/video/transcode`, encodeInfo);
};


























// import Axios from "axios";
// import { apiCall } from "@/services/api";
//
// // CALLED FROM AND RETURN DIRECTLY TO COMPONENT UPLOADER
// // export const fetchS3SignedRequest = async (userId, data) => {
// //   return await apiCall("post", `/api/user/${userId}/sign_s3`, data);
// // };
//
// // UPLOAD TO S3 WITH SIGNED URL
// export const uploadToS3 = (
//   file,
//   signedRequest,
//   progressCallback = () => {
//     return;
//   }
// ) => {
//   // console.log('signed url:', signedRequest);
//   // console.log('file:', file);
//
//   const options = {
//     headers: {
//       "Content-Type": file.type
//     }
//   };
//
//   let instance = Axios.create();
//   const myInterceptor = instance.interceptors.request.use(function(config) {
//     config.headers.common = { Accept: "application/json, text/plain, */*" };
//     config.onUploadProgress = progressEvent =>
//       progressCallback(Math.round((progressEvent.loaded / file.size) * 100));
//     return config;
//   });
//
//   return instance
//     .put(signedRequest, file, options)
//     .then(res => {
//       instance.interceptors.request.eject(myInterceptor);
//       return res;
//     })
//     .catch(err => {
//       console.log(err)
//       instance.interceptors.request.eject(myInterceptor);
//     });
// };
//
// // NOTE: LOOP OVER FILES TO UPLOAD
// export const uploadMultipleFiles = async (files, signedUrls) => {
//   // console.log(files)
//   // console.log(signedUrls)
//
//   // loop over files to upload content
//   for (var x in files) {
//     if ({}.hasOwnProperty.call(files, x)) {
//       let url = signedUrls[x];
//       let file = files[x];
//       // console.log(file);
//       // console.log(url);
//       await uploadToS3(file, url);
//     }
//   }
// };
//
// // export const uploadMultiPart = async (userId, data, file) => {
// //   let uploadInfo = await apiCall("post",`/api/user/${userId}/media/upload/multi`,data);
// //
// //   let { params, partList } = uploadInfo;
// //
// //   console.log(file, partList)
// //
// //   let etagList = await uploadParts(file, partList);
// //
// //   let info = {
// //     params,
// //     etagList
// //   };
// //
// //   console.log(info)
// //
// //   await apiCall("post", `/api/user/${userId}/media/upload/multi/close`, info);
// //
// //   return "success";
// // };
//
// export const uploadMultiPart = async (
//   userId,
//   uploadInfo,
//   file,
//   progressCallback = () => {
//     return;
//   }
// ) => {
//   let { params, partList } = uploadInfo;
//
//   // console.log(file, partList);
//
//   let etagList = await uploadParts(file, partList, progressCallback);
//
//   let info = {
//     params,
//     etagList
//   };
//
//   // console.log(info);
//
//   await apiCall("post", `/user/${userId}/data/upload/multi/close`, info);
//
//   return "success";
// };
//
// export const uploadParts = async (file, urls, progressCallback) => {
//   const FILE_CHUNK_SIZE = 10000000;
//
//   const parts = Math.ceil(file.size / FILE_CHUNK_SIZE);
//   let progression = 0;
//
//   let partCompleted = () => {
//     progression = ++progression;
//     progressCallback(((progression / parts) * 100).toFixed(1));
//   };
//
//   let instance = Axios.create();
//   const myInterceptor = instance.interceptors.request.use(function(config) {
//     config.headers.common = { Accept: "application/json, text/plain, */*" };
//
//     config.onUploadProgress = progressEvent => {
//       // progressCallback(Math.round((progressEvent.loaded / file.size) * 100));
//       if (progressEvent.loaded === FILE_CHUNK_SIZE) {
//         partCompleted();
//       }
//     };
//
//     return config;
//   });
//
//   const keys = Object.keys(urls);
//   console.log(keys, FILE_CHUNK_SIZE);
//   const promises = [];
//
//   for (const indexStr of keys) {
//     console.log(indexStr);
//     const index = parseInt(indexStr) - 1;
//     const start = index * FILE_CHUNK_SIZE;
//     const end = (index + 1) * FILE_CHUNK_SIZE;
//     const blob =
//       index < keys.length ? file.slice(start, end) : file.slice(start);
//
//     console.log(urls[index + 1], blob);
//
//     promises.push(instance.put(urls[index + 1], blob));
//     // .then(res => {
//     //   console.log(res.headers)
//     //   instance.interceptors.request.eject(myInterceptor);
//     //   return res;
//     // })
//     // .catch(() => {
//     //   instance.interceptors.request.eject(myInterceptor);
//     // }));
//   }
//
//   const resParts = await Promise.all(promises);
//
//   console.log(resParts);
//
//   let tags = resParts.map((part, index) => {
//     return {
//       ETag: part.headers.etag,
//       PartNumber: index + 1
//     };
//   });
//
//   instance.interceptors.request.eject(myInterceptor);
//
//   return tags;
// };
//
// export const transcodeAudio = async (userId, encodeInfo) => {
//   await apiCall("post", `/user/${userId}/audio/transcode`, encodeInfo);
// };
//
// export const transcodeVideo = async (userId, encodeInfo) => {
//   await apiCall("post", `/user/${userId}/video/transcode`, encodeInfo);
// };
