import { createRouter, createWebHistory } from '@ionic/vue-router';
// import { RouteRecordRaw } from 'vue-router';
import AppLanding from '../views/AppLanding.vue'
import SignIn from '../views/SignIn.vue'
import SignUp from '../views/SignUp.vue'
import AppHome from '../views/AppHome.vue'
import AppSearch from '../views/AppSearch.vue'
import AppMatch from '../views/AppMatch.vue'
import AppApplication from '../views/AppApplication.vue'
import AppMap from '../views/AppMap.vue'
import AppChat from '../views/AppChat.vue'

const routes = [
  {
    path: '/',
    redirect: '/landing'
  },
  {
    path: '/landing',
    name: 'AppLanding',
    component: AppLanding
  },
  {
    path: '/home',
    name: 'AppHome',
    component: AppHome
  },
  {
    path: '/search',
    name: 'AppSearch',
    component: AppSearch
  },
  {
    path: '/staff',
    name: 'AppMatch',
    component: AppMatch
  },
  {
    path: '/application',
    name: 'AppApplication',
    component: AppApplication
  },
  {
    path: '/map',
    name: 'AppMap',
    component: AppMap
  },
  {
    path: '/chat',
    name: 'AppChat',
    component: AppChat
  },
  {
    path: '/signin',
    name: 'SignIn',
    component: SignIn
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: SignUp
  }
  // {
  //   path: '/signin',
  //   name: 'SignIn',
  //   component: () => import ("@/components/auth/createUser/UserCreator.vue")
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
