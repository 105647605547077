<template>
  <div :class='{employer: currentUser.userMode==="employer"}' class="bottom-bar">
    <div v-if='currentUser.userMode==="staff"' @click='navTo("/home")' class="option" :class='{selected: $route.path==="/home"}'>
      <FontAwesomeIcon icon='user-astronaut'></FontAwesomeIcon>
      <h5>{{ $t('sides') }}</h5>
    </div>
    <div v-if='currentUser.userMode==="employer"' @click='navTo("/home")' class="option" :class='{selected: $route.path==="/home"}'>
      <FontAwesomeIcon icon='user-astronaut'></FontAwesomeIcon>
      <h5>{{ $t('prospects') }}</h5>
    </div>
    <div @click='navTo("/search")' class="option" :class='{selected: $route.path==="/search"}'>
      <FontAwesomeIcon icon='search'></FontAwesomeIcon>
      <h5>{{ $t('search') }}</h5>
    </div>
    <div @click='openUserMenu' class="option user-menu">
      <FontAwesomeIcon icon='house-chimney'></FontAwesomeIcon>
      <img v-if='currentUser.user.profileImage.small' :src="currentUser.user.profileImage.small" alt="my-picture">
      <img v-else src="https://images.unsplash.com/photo-1635373670332-43ea883bb081?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=200&q=80" alt="my-picture">
    </div>
    <!-- <div @click='navTo("/profile")' class="option" :class='{selected: $route.path==="/profile"}'>
      <img :src=''></ing>
    </div> -->
    <div v-if='currentUser.userMode==="employer"' @click='navTo("/staff")' class="option" :class='{selected: $route.path==="/staff"}'>
      <FontAwesomeIcon icon='heart'></FontAwesomeIcon>
      <h5>{{ $t('staff') }}</h5>
    </div>
    <!-- <div v-if='currentUser.userMode==="staff"' @click='navTo("/map")' class="option" :class='{selected: $route.path==="/map"}'>
      <FontAwesomeIcon icon='map-marker-alt'></FontAwesomeIcon>
      <h5>{{ $t('map') }}</h5>
    </div> -->
    <div v-if='currentUser.userMode==="staff"' @click='navTo("/application")' class="option" :class='{selected: $route.path==="/application"}'>
      <FontAwesomeIcon icon='check'></FontAwesomeIcon>
      <h5>{{ $t('applications') }}</h5>
    </div>
    <div class="option">
    </div>
    <!-- <div @click='navTo("/chat")' class="option" :class='{selected: $route.path==="/chat"}'>
      <FontAwesomeIcon icon='comments'></FontAwesomeIcon>
      <h5>{{ $t('chat') }}</h5>
    </div> -->
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'BottomBar',
  props: {
    currentUser: Object
  },
  methods: {
    navTo(to){
      this.$router.push(to)
    },
    openUserMenu(){
      this.toggleVisibility()
    },
    ...mapActions({
      toggleVisibility: 'userMenu/toggleVisibility'
    })
  }
}
</script>
