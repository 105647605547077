<template>
  <div class="pending-application">

    <ul class='side-list'>
      <li v-for='(app, index) in list' :key='index'>
        <div class="line">
          <h3 class='title'>{{ app.side.job.name }}</h3>
          <!-- <h4 class='status'>{{ app.status }}</h4> -->
          <h5 class='date'>{{ parseDate(app.side.date) }}</h5>
        </div>
        <div class="user-label">
          <img :src='app.employer.profileImage.small' />
          <h5>{{ app.employer.name || `${app.employer.firstname} ${app.employer.lastname}` }}</h5>
        </div>
        <div class="">
          <!-- <button>{{ $t('see more') }}</button> -->
          <button class='cancel' @click='cancelSide(app)'>{{ $t('cancel') }}!</button>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import * as dayjs from 'dayjs';

import { getStaffSideApplicationList, cancelSideApplication } from '@/apiCall/sides.js';

export default {
  name: 'PendingApplication',
  data(){
    return {
      list: [],
      selectedApplication: null,
      decisionMessage: ''
    };
  },
  props: {
    currentUser: Object,
    setAlert: Function
  },
  methods: {
    parseDate(date){
      return dayjs(date).format('LL');
    },
    selectPage(page){
      this.page = page
    },
    async fetchApplications(){
      try {

        this.list = await getStaffSideApplicationList(this.currentUser.user._id, 'awaitingConfirmation')

      } catch (e) {
        console.log(e)
      }
    },
    messageUser(user){
      console.log(user)
    },
    async cancelSide(side){
      try {

        await cancelSideApplication(this.currentUser.user._id, side._id)

        this.setAlert({
          type: 'info',
          data: {
            status: 'success',
            message: this.$t('Operation successfull')
          }
        })

        this.fetchApplications();

      } catch (e) {
        console.log(e)
      }
    }
  },
  mounted(){
    this.fetchApplications();
  }
}
</script>
