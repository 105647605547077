<template>
  <div class="account-type">
    <h1>{{ $t('Language of preference')}}</h1>
    <p>{{ $t('Please select your prefered language')}}</p>

    <div class="selection-options">

      <OptionCard
      @click='selectType("en")'
      :title='$t("English")'
      :desc="$t('I speak in english')"
      />

      <OptionCard
      @click='selectType("fr")'
      :title='$t("Français")'
      :desc="$t('Je parle en français')"
      />

      <OptionCard
      @click='selectType("es")'
      :title='$t("Español")'
      :desc="$t('Hablo en español')"
      />

    </div>
  </div>
</template>

<script>
import OptionCard from '@/components/genComponents/OptionCard.vue';

import * as dayjs from 'dayjs';

export default {
  name: 'AuthPrefLanguage',
  components: {
    OptionCard
  },
  methods: {
    selectType(lng){
        this.$i18n.locale = lng;
        dayjs.locale(lng);
      this.$emit('pushData', {
        prefLanguage: lng
      })
    }
  }
}
</script>
