<template>
  <div class="user-menu-modal">
    <h3 class='menu-title'>{{ $t('Payments') }}</h3>


  </div>
</template>

<script>
export default {
  name: 'UserPayments',
  props: {
    currentUser: Object
  }
}
</script>
