const DEFAUT_STATE = {
  type: null,
  data: {}
};

export const alert = {
  namespaced: true,
  state() {
    return DEFAUT_STATE;
  },
  getters: {
    getBrowserAlert(state) {
      return state;
    }
  },
  mutations: {
    SET_ALERT(state, data) {
      state.type = data.type;
      state.data = data.data;
    },
    CLOSE_ALERT(state) {
      state.type = null;
      state.data = {};
    }
  },
  actions: {
    setAlert({ commit }, data) {
      commit("SET_ALERT", data);
    },
    resetAlert({ commit }) {
      commit("CLOSE_ALERT");
    }
  }
};
