<template>
  <div class="alert-info" :class="alert.data.status">
    <FontAwesomeIcon
      v-if="alert.data.icon !== undefined"
      :icon="alert.data.icon"
    />

    <img v-if="alert.data.image !== undefined" :src="alert.data.image" />

    <h1 class="alert-msg">{{ alert.data.message }}</h1>
    <span class="close" @click="this.removeAlert">&times;</span>
  </div>
</template>

<script>
export default {
  name: "AlertInfo",
  data() {
    return {
      timer: null
    };
  },
  props: {
    alert: Object,
    removeAlert: Function
  },
  methods: {
    prepareToFade() {
      let duration = this.alert.data.message.length * 100;
      this.timer = setTimeout(() => {
        this.removeAlert();
      }, duration);
    }
  },
  mounted() {
    this.prepareToFade();
  },
  beforeUnmount() {
    clearTimeout(this.timer);
  }
};
</script>
