import { apiCall } from "../services/api";
import { uploadMultipleFiles } from "./s3/fileUpload";


export const getUserInfo = async userId => {
  return await apiCall("get", `/api/user/${userId}/profile/user-info`);
};

export const getUserProfile = async (userId, targetUser) => {
  return await apiCall("get", `/api/user/${userId}/profile/${targetUser}`);
};

export const updateUserAccount = async (userId, data, files=null) => {
  let response = await apiCall("put", `/api/user/${userId}/profile`, data);

  console.log('files',files)

  if(files!==null){
    await uploadMultipleFiles(files, response.signedUrls);
  }
  return response.profile;
};

export const getStaffList = async (userId, data) => {
  let { lat, long, type } = data;
  return await apiCall("get", `/api/user/${userId}/profile/search?lat=${lat}&long=${long}&type=${type}`);
};



export const toggleToFavoriteStaff = async (userId, staffId) => {
  return await apiCall("post", `/api/user/${userId}/profile/staff-favorite`, {staffId});
};
