import {
  // getMyPosition,
  nativeGeoLocate,
  // getTravelPosition
} from "@/services/geoLocation.js";

import { getGeolocation } from "@/apiCall/data.js";
// import { getFormatedLocation } from "@/apiCall/data/location/index.js";
// import { logAppError } from '@/apiCall/errorHandler';

const DEFAUT_STATE = {
  loaded: false,
  travelMode: false,
  data: {}
};

export const location = {
  namespaced: true,
  state() {
    return DEFAUT_STATE;
  },
  getters: {
    userLocation(state) {
      return state;
    },
  },
  mutations: {
    SET_USER_LOCATION(state, data) {
      state.loaded = true;
      state.travelMode = false;
      state.data = { ...data };
    },
    SET_TRAVEL_MODE(state, data) {
      state.loaded = true;
      state.travelMode = true;
      state.data = { ...data };
    },
    CLEAR_STATE(state) {
      state.loaded = false;
      state.travelMode = false;
      state.data = {};
    }
  },

  actions: {
    async initLocation({ commit }, data) {
      let { latitude, longitude } = data;

      let location;
      try {
        location = await getGeolocation({latitude,longitude});

      } catch (e) {
        console.log(e)
        // await logAppError(data)
      }

      commit("SET_USER_LOCATION", location);
    },

    async activateTravelMode({ commit }, data) {
      let { latitude, longitude } = data;

      let location;
      try {
        location = await getGeolocation({latitude,longitude});

      } catch (e) {
        console.log(e)
        // await logAppError(data)
      }

      commit("SET_TRAVEL_MODE", location);
    },

    async getPreciseLocation({ commit }) {
    // async getPreciseLocation({ commit }, data) {
      // const { loc } = data;
      let coordinates;

      try {
        coordinates = await nativeGeoLocate();
        // coordinates = await getMyPosition();
        const location = await getGeolocation(coordinates);

        // let data = await getFormatedLocation(position.coords);
        commit("SET_USER_LOCATION", location);

      } catch (e) {
        console.log(e)

        // await logAppError(data)
        // coordinates = loc;
      }



    },
  }
};
