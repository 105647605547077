<template>
  <div class="autocomplete-cont">
    <input :placeholder="$t('Start typing your address')" type="text" autocomplete='nope' v-model="address" />

    <ul v-if="resultList.length > 0">
      <li v-for="res in resultList" :key="resultList.indexOf(res)">
        <h5 @click="setAddress(res.address.label)">
          {{ res.address.houseNumber || null }}
          {{ res.address.street || null }},
          <span class="dist">{{ (res.distance / 1000).toFixed(2) }}Km</span>
          <br />
          <span class="region">
            {{ res.address.city || null }}, {{ res.address.county || null }},
            {{ res.address.countryCode || null }}
          </span>
        </h5>
      </li>
    </ul>

    <!-- <h5 v-if="autocomplete.length > 3 && resultList.length === 0">
      Sorry no match found
    </h5> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  autoComplete,
  getAddressFromCoords
} from "@/apiCall/data.js";

export default {
  name: "AddressAutocomplete",
  // props: {
  //   t: Function,
  //   setLocation: Function
  // },
  data() {
    return {
      address: "",
      resultList: []
    };
  },
  computed: {
    ...mapGetters({
      location: "location/userLocation",
    })
  },
  methods: {
    async setAddress(address) {
      let selection = await getAddressFromCoords({ query: address });

      (this.autocomplet = ""), (this.resultList = []);

      this.$emit('pushData', selection)
    },
    async handleAutocomplete() {
      let data = {
        loc: {
          lat: this.location.data.latitude,
          long: this.location.data.longitude
        },
        query: this.address
      };

      let res = await autoComplete(data);

      let filteredList = [];
      for (var i = 0; i < res.length; i++) {
        if (res[i].address.houseNumber) {
          filteredList.push(res[i]);
        }
      }

      this.resultList = filteredList;
    }
  },
  watch: {
    address(val) {
      if (val.length > 8) {
        this.handleAutocomplete();
      }
    }
  }
};
</script>
