import { apiCall } from "../services/api";
import { uploadMultipleFiles } from "./s3/fileUpload";


export const createUserAccount = async (data, files=null) => {
  let response = await apiCall("post", `/api/user/auth/signup`, data);

  console.log('files',files)

  if(files!==null){
    await uploadMultipleFiles(files, response.signedUrls);
  }
  return response.profile;
  };



export const signInUser = async data => {
  return apiCall("post", `/api/user/auth/signin`, data);
};



export const refreshUserSession = async data => {
  return apiCall("post", `/api/user/auth/refresh`, data);
};
