<template>
  <div v-if='loaded===true' class="vert-cont user-profile-modal">

    <div v-if='user.userType==="Individual"' class="vert-cont user-profile-modal">

      <FontAwesomeIcon v-if='isInFavoriteList===false' @click='toggleLove' class='love-icon' icon='fa-regular fa-heart'></FontAwesomeIcon>
      <FontAwesomeIcon v-else @click='toggleLove' class='love-icon selected' icon='fa-solid fa-heart'></FontAwesomeIcon>

      <img style='border-radius: 50%;width:140px;height:140px;margin:10px;' :src='user.profileImage.medium' />

      <h2 class='big-title'>{{ `${user.firstname} ${user.lastname}` }}</h2>

      <div class="top-info-cont">

        <h5 class='sml-para'>{{ user.DOB }}</h5>

        <div class="main-cont">

          <div class="left-cont inner-cont">
            <div class="location-label">
              <FontAwesomeIcon class='marker-icon' icon='map-marker-alt'></FontAwesomeIcon>
              <h5>{{ user.location.civicLocation.city }}</h5>
            </div>

            <div v-if='user.usualAvailability&&user.usualAvailability[0]' class="availability-cont">
              <h4>{{ $t('Availability')}}</h4>
              <ul>
                <li v-for='(value, day, index) in user.usualAvailability' :key='index'>
                  <h4>{{ parseDOW(index) }}</h4>
                  <h5>{{ parsedAvailabilty(day) }}</h5>
                </li>
              </ul>
            </div>
          </div>


          <div class="inner-cont">
            <ul class="Industry-list">
              <li>
                <!-- <li v-for='(indus, index) in user.industry' :key='index'> -->
                <FontAwesomeIcon icon='ustensils'></FontAwesomeIcon>
                <!-- <h4>{{ $t('Industry') }}</h4> -->
              </li>
            </ul>

            <div v-if='user.staffRating' class="review-cont">
              <!-- <h4>{{ $t('Review') }}</h4> -->
              <div class="rate-cont">
                <FontAwesomeIcon class='1' :class='{selected: user.staffRating.average >= 1}' icon='star'></FontAwesomeIcon>
                <FontAwesomeIcon class='2' :class='{selected: user.staffRating.average >= 2}' icon='star'></FontAwesomeIcon>
                <FontAwesomeIcon class='3' :class='{selected: user.staffRating.average >= 3}' icon='star'></FontAwesomeIcon>
                <FontAwesomeIcon class='4' :class='{selected: user.staffRating.average >= 4}' icon='star'></FontAwesomeIcon>
                <FontAwesomeIcon class='5' :class='{selected: user.staffRating.average >= 5}' icon='star'></FontAwesomeIcon>
              </div>
              <p class='based-on'>
                {{ $t('Based on')}} {{ user.staffRating.evalQte }} {{ $t('reviews') }}
              </p>
            </div>

            <h5 class='very-small-title'>{{ $t('No rating yet')}}</h5>

          </div>
        </div>
      </div>


      <div class="cv-cont">
        <div class="info-cont">
          <h4>{{ $t('Lng') }}</h4>
          <h5>{{ user.prefLanguage }} {{ user.offeredLanguages }}</h5>
          <FontAwesomeIcon icon='comments'></FontAwesomeIcon>
        </div>

        <div class="info-cont">
          <h4>{{ $t('Skills') }}</h4>
          <h5>{{ user.skills }}</h5>
          <FontAwesomeIcon icon='toolbox'></FontAwesomeIcon>
        </div>

        <div class="info-cont">
          <!-- <h4>{{ $t('About me') }}</h4> -->
          <p class='sml-para'>
            {{ user.aboutMe }}
          </p>
          <FontAwesomeIcon icon='circle-info'></FontAwesomeIcon>
        </div>


        <!-- NOTE CV -->


      </div>

    </div>
  </div>
</template>

<script>
import * as dayjs from 'dayjs';

import { getUserProfile, toggleToFavoriteStaff } from '@/apiCall/profile.js'

import { mapActions } from 'vuex';

export default {
  name: 'UserProfileModal',
  data(){
    return {
      loaded: false,
      user: {}
    };
  },
  props: {
    modalData: Object,
    currentUser: Object
  },
  computed: {
    // user(){
    //   return this.modalData.user;
    // },
    isInFavoriteList(){
      let isFav = false;
      for (var i = 0; i < this.currentUser.user.favStaffList.length; i++) {
        let target = this.currentUser.user.favStaffList[i]
        if(target._id===this.user._id){
          isFav = true;
          break;
        }
      }
      return isFav;
    }
  },
  methods: {
    async fetchUser(){
      try {

        this.user = await getUserProfile(this.currentUser.user._id,  this.modalData.user._id)
        this.loaded = true;

      } catch (e) {
        console.log(e)
      }
    },
    parseDate(date){
      return dayjs(date).format('LL');
    },
    parseDOW(index){
      let day = [
        'sunday',
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
        'saturday',
      ]

      return day[index];
    },
    parsedAvailabilty(a){
      let availa = ''
      for (var i = 0; i < a.length; i++) {
        let p = a[i];
        availa.concat(`${p[0]} - ${p[1]}`, ' ')
      }
      return availa;
    },
    async toggleLove(){
      try {

        await toggleToFavoriteStaff(this.currentUser.user._id, this.user._id)
        console.log('hello')

        this.updateUser(this.currentUser.user._id)

      } catch (e) {
        console.log(e)
      }
    },
    ...mapActions({
      updateUser: 'user/updateUserInfo'
    })
  },
  mounted(){
    this.fetchUser()
  }
}
</script>
