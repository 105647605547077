<template>
  <ul class='home-staff'>

    <li class="list">
      <h3>{{ $t('new')}}</h3>
      <SidesList :currentUser='currentUser' listType='new'></SidesList>
    </li>

    <li class="list">
      <h3>{{ $t('near')}}</h3>
      <SidesList :currentUser='currentUser' listType='near'></SidesList>
    </li>

    <li class="list">
      <h3>{{ $t('restauration')}}</h3>
      <SidesList :currentUser='currentUser' listType='new'></SidesList>
    </li>

    <!-- <li class="list">
      <h3>{{ $t('for you')}}</h3>
      <SidesList :currentUser='currentUser' listType='forYou'></SidesList>
    </li> -->
  </ul>
</template>

<script>
import SidesList from '@/components/list/SidesList.vue';

export default {
  name: 'HomeStaff',
  components: {
    SidesList
  },
  props: {
    currentUser: Object
  }
}
</script>
