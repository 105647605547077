<template>
  <div class="user-menu-modal">
    <h3 class='menu-title'>{{ $t('Travel mode') }}</h3>

    <div v-if='location.travelMode===false' class="">
      <AddressAutocomplete  @pushData='chooseLocation' />

      <h5 v-if='selectedLocation!==null'>{{ $t('Travel to')}} : {{ selectedLocation.label }}</h5>

      <button class='confirm' @click='travel'>{{ $t('Travel!')}}</button>
    </div>

    <div v-else class="">
      <h5>{{ $t('Currently in travel mode to')}} : {{ location.data.label}}</h5>
      <button class='confirm' @click='resetLocation'>{{ $t('reset location')}}</button>
    </div>


    <!-- <ul>
      <li @click='goToUser(user)' v-for='(user, index) in loveList' :key='index'>
        <div class="user-label">
          <img :src='user.profileImage.small' />
          <h5>{{ user.name || `${user.firstname} ${user.lastname}` }}</h5>
        </div>
      </li>
    </ul> -->
  </div>
</template>

<script>
import AddressAutocomplete from '@/components/formComponents/AddressAutoComplete.vue'

import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'UserTravelMode',
  data(){
    return {
      selectedLocation: null
    };
  },
  props: {
    currentUser: Object
  },
  components: {
    AddressAutocomplete
  },
  computed: {
    ...mapGetters({
      location: 'location/userLocation'
    })
  },
  methods: {
    chooseLocation(location){
      console.log(location)
      this.selectedLocation = location;
    },
    resetLocation(){
      this.getPreciseLocation()
    },
    travel(){
      this.activateTravelMode({
        latitude:this.selectedLocation.latitude,
        longitude: this.selectedLocation.longitude
      })

      this.setAlert({
        type: 'info',
        data: {
          status: 'success',
          message: this.$t('Travel mode activated')
        }
      })

      this.$emit('backToMenu')

    },
    ...mapActions({
      setAlert: 'alert/setAlert',
      activateTravelMode: 'location/activateTravelMode',
      getPreciseLocation: 'location/getPreciseLocation'
    })
  }
}
</script>
