<template>
  <div class="vert-cont side-visualizer">

    <!-- NOTE EMPLOYER INFO -->
    <img class='main-img' v-if='side.employerId.profileImage.medium' :src='side.employerId.profileImage.medium' />

    <div class="hori-cont info-header">

      <div class="inner-cont">
        <div class='line'>
          <h3 class='title'>{{ side.employerId.name || `${side.employerId.firstname} ${side.employerId.lastname}` }}</h3>
          <h4>{{ side.job.industry }}</h4>
        </div>

        <div class="location-label">
          <FontAwesomeIcon class='marker-icon' icon='map-marker-alt'></FontAwesomeIcon>
          <h5>{{ side.job.location.civicLocation.label }}</h5>
        </div>

        <div v-if='side.employerId.aboutMe' class='line'>
          <p>
            {{ side.employerId.aboutMe }}
          </p>
        </div>

      </div>
    </div>

    <div class="job-info">
      <h4 class='title'>{{ side.job.name }}</h4>

      <div class="info-cont">
        <FontAwesomeIcon icon='calendar'></FontAwesomeIcon>
        <h4>{{ $t('Date') }}</h4>
        <h5 class=''>{{ parseDate(side.date) }}</h5>
      </div>

      <div class="info-cont">
        <FontAwesomeIcon icon='dollar-sign'></FontAwesomeIcon>
        <h4>{{ $t('Salary') }}</h4>
        <h5 class=''>{{ `${side.job.salaryRate}$ / ${side.job.rateType}` }}</h5>
      </div>

      <div class="info-cont">
        <h4>{{ $t('Lng') }}</h4>
        <h5>{{ side.job.language }}</h5>
        <FontAwesomeIcon icon='comments'></FontAwesomeIcon>
      </div>

      <div class="info-cont">
        <h4>{{ $t('Skills') }}</h4>
        <h5>{{ side.job.skillsQualifications }}</h5>
        <FontAwesomeIcon icon='toolbox'></FontAwesomeIcon>
      </div>

      <!-- <h5 class=''>{{ `${side.job.language}` }}</h5> -->

      <!-- <p class=''>{{ `${side.job.skillsQualifications}` }}</p> -->
      <p>
        {{ side.job.description }}
      </p>

      <button v-if='hasApplied===false' @click='apply'>{{ $t('Apply') }}</button>
      <h4 style='opacity: 0.4;' v-else>{{ $t('Already applied') }}</h4>

      <!-- NOTE NEXT OPENING -->

    </div>

  </div>
</template>

<script>
import * as dayjs from 'dayjs';

import { applyToSide, hasApplied } from '@/apiCall/sides.js';

import { mapActions } from 'vuex';

export default {
  name: 'SideVisualizer',
  data(){
    return {
      hasApplied: false,
      message: null
    };
  },
  props: {
    modalData: Object,
    currentUser: Object
  },
  computed: {
    side(){
      return this.modalData.side;
    }
  },
  methods: {
    parseDate(date){
      return dayjs(date).format('LLLL');
    },
    async validIfApplied(){
      try {

        console.log('hello')
        this.hasApplied = await hasApplied(this.currentUser.user._id, this.side._id)

      } catch (e) {
        console.log(e)
      }
    },
    async apply(){
      try {

        let data = {
          sideId: this.side._id,
          employerId: this.side.employerId,
          message: this.message
        }

        await applyToSide(this.currentUser.user._id, this.side._id, data);
        console.log('hello')

        this.hasApplied = true;

        this.setAlert({
          type: 'info',
          data: {
            status: 'success',
            message: this.$t('Operation successfull')
          }
        })

      } catch (e) {
        console.log(e)
        this.setAlert({
          type: 'info',
          data: {
            status: 'error',
            message: this.$t('Operation failed')
          }
        })
      }
    },
    // async toggleLove(){
    //   try {
    //
    //     console.log('hello')
    //
    //   } catch (e) {
    //     console.log(e)
    //   }
    // }
    ...mapActions({
      setAlert: 'alert/setAlert'
    })
  },
  mounted(){
    this.validIfApplied()
  }
}
</script>
