import { toURL } from "./index.js";
import { getVibrant } from "./vibrantColors";

export const multiImageUploadFormater = async imagesBundle => {
  let files = {};
  let filesInfo = [];

  for (var i = 0; i < imagesBundle.length; i++) {
    let image = imagesBundle[i];

    let processedData = await multiSizePictureFormatter(image);

    console.log(processedData);
    // let { name, info, files } = await multiSizePictureFormatter(image)

    files[processedData.name] = processedData.files;
    filesInfo.push(processedData.info);
  }

  console.log(files, "hello");

  return { files, filesInfo };
};

// LATEST VERSION TO USE ***** TAKE ARRAY!!!!
export const multiImageFormater = async imagesBundle => {
  let fileLists = {
    mediaInfo: [],
    files: []
  };

  // NOTE: ITERATE OVER EACH IMAGE GROUP
  for (var x in imagesBundle) {
    if ({}.hasOwnProperty.call(imagesBundle, x)) {
      let bundle = imagesBundle[x];

      let bundleInfo = {
        // NOTE: INFO TO SEND TO SERVER
        name: "", // NOTE: GLOBAL NAME
        vibrant: {}, // NOTE: GOLBAL VIBRANT INFO
        data: [] // NOTE: S3 UPDATE INFO / IMAGE SIZE
      };

      let bundleFiles = []; // NOTE: FILES PREPARED FOR UPLOAD
      // let bundleFiles = {}; // NOTE: FILES PREPARED FOR UPLOAD

      let fileFullName = bundle[0].file.name.split(".");
      bundleInfo.name = fileFullName[0]; // ORIGINAL NAME OF FILE WITHOUT EXTENSION

      let url = toURL(bundle[0].file); // CREATE URL FOR VIBRANT
      let vibrant = await getVibrant(url); // EXTRACT VIBRANT COLORS
      bundleInfo.vibrant = vibrant;

      for (var i = 0; i < bundle.length; i++) {
        // LOOP OVER BUNDLE TO FORMAT INFORMATION
        let thisFile = bundle[i];
        console.log(thisFile);

        let newFileName = `${thisFile.name}${fileFullName[0]}`; // RENAME FILE = SIZE+NAME

        let fileInfo = {
          // fileFormat: thisFile.name, // 'name' ref to filePond output transformation
          fileSize: thisFile.size,
          fileName: newFileName,
          fileExtension: fileFullName[1],
          fileType: bundle[0].file.type
          // fileSize: thisFile.name, // 'name' ref to filePond output transformation
        };

        bundleInfo.data.push(fileInfo); // ARRAY OF FILE INFO FOR SIGNED URL
        bundleFiles.push(thisFile.file); // CREATE ARRAY TO CLASS FILES FOR EASIER UPLOADING
        // bundleFiles[newFileName] = thisFile.file; // CREATE ARRAY TO CLASS FILES FOR EASIER UPLOADING
      }

      fileLists.mediaInfo.push(bundleInfo);
      fileLists.files.push(bundleFiles);
    }
  }

  return fileLists;
};
//
//
//
//
//
//
export const rawFileFormater = (mediaType, fileBundle, path) => {
  // TODO:
  // 1 Video === info: name, ext, tempPath (in path)
  // 2 Audio === info: name, ext, tempPath (in path)
  // 3 Files === info: name, ext

  let fileLists = {
    mediaInfo: [], // NOTE:
    files: [] // NOTE:  LIST OF OBJECT {FILENAME: FILE}
  };

  for (var file in fileBundle) {
    if ({}.hasOwnProperty.call(fileBundle, file)) {
      let rawFile = fileBundle[file];
      let rawName = rawFile.name.split(".");

      let bundleInfo = {
        name: "",
        data: [
          {
            fileSize: rawFile.size,
            fileName: rawName[0],
            fileExtension: rawName[1],
            fileType: rawFile.type
          }
        ]
      };

      let bundleFiles = [rawFile];
      // let bundleFiles = {
      //   [fileName]: rawFile.file
      // };

      // media.info.fileName = rawName[0];
      // media.info.fileExtension = rawName[1];
      // media.info.fileSize = rawFile.size;
      // // media.info.filePath = path;
      // media.info.fileType = rawFile.type;

      if (mediaType === "audio" || mediaType === "video") {
        bundleInfo.data[0].inPath = `${path}/${rawName[0]}.${rawName[1]}`;
      }
      fileLists.mediaInfo.push(bundleInfo);
      fileLists.files.push(bundleFiles);
    }
  }

  return fileLists;
};
//
//
//
//
//
//
//
//
//
//
//
export const mediaTypesExtractor = (media) => {
  // console.log(media)
  let type = media.type.split('/')[0]
  let extension = media.type.split('/')[1]

  return { type, extension};
}


//
//
//
//
//
//
//
//
//
//
//

// NOTE: SELECT APPROPRIATE TYPE OF UPLOADER FOR THE FILE

export const mediaFormatterSelector = async (mediaType, media) => {
  console.log("what up", mediaType, media);
  let processedData;

  switch (mediaType) {
    case "image":
      processedData = await multiImageUploadFormater(media);
      // processedData = await multiImageFormater(media);
      break;

    case "video":
    case "audio":
    case "file":
      processedData = await rawFileFormater(mediaType, media);
      break;

    default:
      processedData = await singleFileFormater(media);
  }

  return { mediaType, ...processedData };
};
//
//
//
//
//
//
//
//
//
// **************************************
// **************************************
// **************************************

// NOTE: FORMAT MULTI SIZE IMAGE UPLOAD (ONE FILE)

// export const multiSizePictureFormatter = async bundle => {
//   console.log(bundle);
//
//   const Files = {
//     info: {
//       name: "",
//       data: [],
//       vibrant: {}
//     },
//     files: {}
//   };
//
//   const fileFullName = bundle[0].file.name.split(".");
//   Files.info.name = fileFullName[0]; // ORIGINAL NAME OF FILE WITHOUT EXTENSION
//
//   let url = toURL(bundle[0].file); // CREAETE URL FOR VIBRANT
//   let vibrant = await getVibrant(url); // EXTRACT VIBRANT COLORS
//   Files.info.vibrant = vibrant;
//
//   for (var i = 0; i < bundle.length; i++) {
//     // LOOP OVER BUNDLE TO FORMAT INFORMATION
//     let thisFile = bundle[i];
//     let newFileName = `${thisFile.name}${fileFullName[0]}`; // RENAME FILE = SIZE+NAME
//     let fileInfo = {
//       fileSize: thisFile.name, // 'name' ref to filePond output transformation
//       fileName: newFileName,
//       fileExtension: fileFullName[1],
//       fileType: bundle[0].file.type
//     };
//     Files.info.data.push(fileInfo); // ARRAY OF FILE INFO FOR SIGNED URL
//     Files.files[newFileName] = thisFile.file; // CREATE ARRAY TO CLASS FILES FOR EASIER UPLOADING
//   }
//
//   // NOTE: EXAMPLE
//   // Files = {
//   //   info: {
//   //     name: "myFirstPic",
//   //     data: [{
//   //       fileSize: 'small',
//   //       fileName: 'myFirstPic',
//   //       fileExtension: 'jpeg',
//   //       fileType: 'image/jpeg'
//   //     }],
//   //     vibrant: {
//   //       // NOTE: VIBRANT INFO
//   //     }
//   //   },
//   //   files: {
//   //     myFirstPicSmall: file,
//   //     myFirstPicMedium: file
//   //     myFirstPicLarge: file
//   //   }
//   // }
//
//   return Files;
// };

// **************************************
// ********** LATEST  *******************
// **************************************

// NOTE: FORMAT MULTI SIZE IMAGE UPLOAD (ONE FILE)

export const multiSizePictureFormatter = async bundle => {
  try {
    console.log("bundle", bundle);

    const Files = {
      name: "",
      info: {
        data: [],
        vibrant: {}
      },
      files: {}
    };


    // NOTE: VIBRANT COLORS SECTION
    let url = toURL(bundle[0].file); // CREAETE URL FOR VIBRANT


    let vibrant = await getVibrant(url); // EXTRACT VIBRANT COLORS

    Files.info.vibrant = vibrant;

    for (var i = 0; i < bundle.length; i++) {
      // LOOP OVER BUNDLE TO FORMAT INFORMATION
      let thisFile = bundle[i];

      let { name, extension } = nameFormater(bundle[0].file.name, (i+0))

      if(i===0){
        Files.name = name; // ORIGINAL NAME OF FILE WITHOUT EXTENSION
        Files.info.name = name; // ORIGINAL NAME OF FILE WITHOUT EXTENSION
      }


      let newFileName = `${thisFile.name}_${name}`; // RENAME FILE = SIZE+NAME

      let fileInfo = {
        fileName: newFileName,
        fileSize: thisFile.name, // 'name' ref to filePond output transformation
        fileRawPath: `${newFileName}.${extension}`, //  SIZE+NAME.EXT
        fileType: bundle[0].file.type // IMAGE/JPEG
      };






    // const fileDotSplit = bundle[0].file.name.split(".");
    // // const fileFullName = bundle[0].file.name.split(".");
    // let extension = fileDotSplit.pop();
    //
    // const fileFullName = fileDotSplit.join('');
    //
    //
    // Files.name = fileFullName; // ORIGINAL NAME OF FILE WITHOUT EXTENSION
    // Files.info.name = fileFullName; // ORIGINAL NAME OF FILE WITHOUT EXTENSION
    //
    // // NOTE: VIBRANT COLORS SECTION
    // let url = toURL(bundle[0].file); // CREAETE URL FOR VIBRANT
    // let vibrant = await getVibrant(url); // EXTRACT VIBRANT COLORS
    // Files.info.vibrant = vibrant;
    //
    // for (var i = 0; i < bundle.length; i++) {
    //   // LOOP OVER BUNDLE TO FORMAT INFORMATION
    //   let thisFile = bundle[i];
    //
    //   // let { name, extensinon } = nameFormater(thisFile.name, i+0)
    //
    //
    //   let newFileName = `${thisFile.name}_${fileFullName}`; // RENAME FILE = SIZE+NAME
    //
    //   let fileInfo = {
    //     fileName: newFileName,
    //     fileSize: thisFile.name, // 'name' ref to filePond output transformation
    //     fileRawPath: `${newFileName}.${extension}`, //  SIZE+NAME.EXT
    //     fileType: bundle[0].file.type // IMAGE/JPEG
    //   };





      Files.info.data.push(fileInfo); // ARRAY OF FILE INFO FOR SIGNED URL
      Files.files[newFileName] = thisFile.file; // CREATE ARRAY TO CLASS FILES FOR EASIER UPLOADING
    }

    // NOTE: EXAMPLE
    // Files = {
    //     name: "myFirstPic", // REVIEW: TO DELETE
    //   info: {
    //     data: [{
    //       fileName: 'smallmyFirstPic',
    //       fileSize: 'small',
    //       fileRawPath: 'smallmyFirstPic.jpg',
    //       fileType: 'image/jpeg'
    //     }],
    //     vibrant: {
    //       // NOTE: VIBRANT INFO
    //     }
    //   },
    //   files: {
    //     smallmyFirstPic: file,
    //     mediummyFirstPic: file
    //     largemyFirstPic: file
    //   }
    // }

    console.log("Files", Files);

    return Files;
  } catch (e) {
    console.log(e);
  }
};





export const singleFileFormater = (rawFile, multiIndex=1) => {

  // console.log('rawFile',rawFile)

  // let rawName = rawFile.name.split(".");
  // let nameLength = rawName.length;
  // let extension = rawName[nameLength-1];

  let { name, extension } = nameFormater(rawFile.name, multiIndex)


  const File = {
    name: name,
    // name: rawName[0],
    info: {
      fileUpdatedName: name, // NOTE: NEW NAME == TIMESTAMP
      // fileUpdatedName: Date.now(), // NOTE: NEW NAME == TIMESTAMP
      fileName: name, // NOTE: ORIGINAL NAME
      // fileName: rawName[0], // NOTE: ORIGINAL NAME
      fileExtension: extension,
      // fileExtension: extension,
      fileFullUpdatedName: `${name}.${extension}`,
      // fileFullUpdatedName: `${Date.now()}.${extension}`,
      fileFullName: `${name}.${extension}`,
      // fileFullName: `${Date.now()}.${extension}`,
      fileSize: rawFile.size,
      fileType: rawFile.type
    },
    file: rawFile
  };

  return File;
};




// NOTE STANDARDISE NAME

const nameFormater = (name, iterator=1) => {

  let parsedName = {
    name: '',
    extension: ''
  }


  let rawName = name.split(".");
  let nameLength = rawName.length;

  // let rawNameSize = name.split("_#");
  // let sizePrefix;

  // if(rawNameSize.length > 1){
  //   sizePrefix = rawNameSize[0];
  //   parsedName.name = `${rawNameSize[0]}_${Date.now()}_${iterator}`
  // } else {
    parsedName.name = `${Date.now()}_${iterator}`
  // }

  parsedName.extension = rawName[nameLength-1];

  return parsedName;

}
