// export const industryTypes = [
//   restauration: {
//     name: 'restauration',
//     icon: 'utensils'
//   },
//   construction: {
//     name: 'construction',
//     icon: 'traffic-cone'
//   },
//   security: {
//     name: 'security',
//     icon: 'shield'
//   },
//   informationTechnologies: {
//     name: 'information technologies',
//     icon: 'cloud-arrow-down'
//   },
//   healthCare: {
//     name: 'health care',
//     icon: 'staff-snake'
//   },
//   other: {
//     name: 'other',
//     icon: 'circle-question'
//   }
//   // customerServices: {
//   //   name: 'customer services',
//   //   icon: ''
//   // }
// ];

export const industryTypes = {
  restauration: {
    name: 'restauration',
    icon: 'utensils'
  },
  construction: {
    name: 'construction',
    icon: 'traffic-cone'
  },
  security: {
    name: 'security',
    icon: 'shield'
  },
  informationTechnologies: {
    name: 'information technologies',
    icon: 'cloud-arrow-down'
  },
  healthCare: {
    name: 'health care',
    icon: 'staff-snake'
  },
  other: {
    name: 'other',
    icon: 'circle-question'
  }
  // customerServices: {
  //   name: 'customer services',
  //   icon: ''
  // }
}
