<template>
  <ion-page>
  <ion-content>
    <div class="match">
      <MenuHeader @menuSelection='selectPage' :selected='page' :title="$t('Applications')" :menuOptions="['Pending', 'Confirmed', 'Completed']"></MenuHeader>
      <!-- <img src='https://onthesideapp.s3.amazonaws.com/ressources/logo/Group+1342.png' /> -->
      <component :is='matchPage' :currentUser='currentUser' :setAlert='setAlert'></component>
    </div>
  </ion-content>
</ion-page>
</template>

<script>
import { IonPage, IonContent } from '@ionic/vue';

import MenuHeader from '@/components/genComponents/MenuHeader.vue';
import PendingApplication from '@/components/appApplication/PendingApplication.vue';

// import { getSideApplicationList } from ''

import { mapGetters, mapActions } from  'vuex';

export default {
  name: 'AppApplication',
  data(){
    return {
      page: 'Pending',
      // list: []
    };
  },
  // props: {
  //   // currentUser: Object
  // },
  components: {
    IonPage,
    IonContent,
    MenuHeader,
    PendingApplication
  },
  methods: {
    selectPage(page){
      this.page = page
    },
  //   async fetchMatches(){
  //     try {
  //
  //       this.list = await getSideApplicationList(this.currentUser.user._id)
  //
  //     } catch (e) {
  //       console.log(e)
  //     }
  //   }
  ...mapActions({
    setAlert: 'alert/setAlert'
  })
  },
  computed: {
    matchPage(){
      let page;
      switch (this.page) {
        case 'Pending':
          page = PendingApplication
          break;

        // case 'Confirmed':
        //   page = ConfirmedMatches
        //   break;
        //
        // case 'Completed':
        //   page = CompletedMatches
        //   break;

      }
      return page;
    },
    ...mapGetters({
      currentUser: 'user/currentUser'
    })
  },
  // mounted(){
  //   this.fetchMatches();
  // }
}
</script>
