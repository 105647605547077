<template>
  <div
    ref="alert"
    class="alertModal"
    :class="[
      { fullscreen: alert.type === 'options' || alert.type === 'trueOrFalse' },
      alertComponent.pos
    ]"
  >
    <component
      :is="alertComponent.comp"
      :alert="alert"
      :removeAlert="removeAlert"
    ></component>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import AlertInfo from "./alertType/AlertInfo.vue";
import AlertTrueOrFalse from "./alertType/AlertTrueOrFalse.vue";
import AlertRequest from "./alertType/AlertRequest.vue";

export default {
  name: 'AppAlert',
  components: {
    AlertInfo,
    AlertTrueOrFalse,
    AlertRequest
  },
  data() {
    return {
      timer: null
    };
  },
  methods: {
    removeAlert() {
      this.$refs.alert.classList.remove("alert-fade");
      setTimeout(() => {
        this.$refs.alert.style.display = "none";
        this.closeAlert();
      }, 1000);
    },
    fadeIt() {
      setTimeout(() => {
        this.$refs.alert.classList.add("alert-fade");
      }, 100);
    },
    // prepareToFade(length){
    //   let duration = length * 100;
    //   this.timer = setTimeout(()=>{
    //     this.removeAlert();
    //   }, duration);
    // },
    ...mapActions({
      closeAlert: "alert/resetAlert"
    })
  },
  computed: {
    alertComponent() {
      let alComp;
      let position;
      switch (this.alert.type) {
        case "info":
          alComp = "AlertInfo";
          position = "top";
          break;

        case "trueOrFalse":
          alComp = "AlertTrueOrFalse";
          position = "top";
          break;

        case "requests":
          alComp = "AlertRequest";
          position = "top";
          break;

        // case 'options':
        //   alComp = <AlertOptions alert={alert} removeAlert={this.removeAlert}/>;
        // position = 'top';
        //   break;
        //
        // case 'notifications':
        //   alComp = <AlertOptions alert={alert} removeAlert={this.removeAlert}/>;
        // position = 'bottom';
        //   break;
        //
        // case 'connection update':
        //   alComp = <AlertOptions alert={alert} removeAlert={this.removeAlert}/>;
        // position = 'bottom';
        //   break;

        default:
          alComp = null;
      }

      return {
        comp: alComp,
        pos: position
      };
    },
    ...mapGetters({
      // theme: "browserStyle/browserTheme",
      alert: "alert/getBrowserAlert"
    })
  },
  watch: {
    alert: {
      handler(val) {
        if (val.type !== null) {
          this.$refs.alert.style.display = "flex";
          this.fadeIt();
        }
      },
      deep: true
    }
  },
  beforeUnmount() {
    clearTimeout(this.timer);
  }
};
</script>
