<template>
  <div class='job-offer'>
    <h2>{{ $t('my job offers')}}</h2>

    <button @click='createNewJob'>
      <FontAwesomeIcon icon='plus'></FontAwesomeIcon>
      {{ $t('create job')}}
    </button>

    <ul class='job-list' v-if='list.length > 0'>
      <li @click='selectJob(index)' :class='{selected: selectedJob===index}' v-for='(j, index) in list' :key='index'>
        <FontAwesomeIcon icon='times' @click.stop='deleteJob' class='delete-icon'></FontAwesomeIcon>
        <div class="job-card-employer">
          <!-- img -->
          <h4>{{ j.name }}</h4>
          <p>
            {{ j.description }}
          </p>
          <h5 class='creation-date'>{{ parsedDate(j.createdAt) }}</h5>
        </div>
      </li>
    </ul>

    <h5 v-else>{{ $t('no jobs created yet')}}</h5>


    <div v-if='creator===true' class="job-creator">

      <FontAwesomeIcon class='close-icon' icon='times' @click='creator=false'></FontAwesomeIcon>

      <h5>{{$t('job creator')}}</h5>
      <form @submit.prevent>
        <input v-model='newJob.name' :placeholder="$t('Job name')"/>
        <textarea v-model='newJob.description' :placeholder="$t('Job description')"/>
        <select v-model='newJob.rateType'>
          <option value='hourly'>
            {{ $t('hourly')}}
          </option>
          <option value='daily'>
            {{ $t('daily')}}
          </option>
          <option value='session'>
            {{ $t('session')}}
          </option>
        </select>
        <input v-model='newJob.salaryRate' type='number' />

        <button @click='createJob'>{{ $t('create job!')}}</button>
      </form>
    </div>



  </div>
</template>

<script>
import { createANewJob, getMyJobOffer, deleteAJob } from '@/apiCall/job';
import { mapActions } from 'vuex';

var relativeTime = require('dayjs/plugin/relativeTime')
import * as dayjs from 'dayjs';
dayjs.extend(relativeTime)

export default {
  name: 'MyJobOffers',
  data(){
    return {
      list: [],
      selectedJob: null,
      creator: false,
      newJob: {
        name: '',
        description: '',
        rateType: 'hourly',
        salaryRate: 10
      }
    };
  },
  props: {
    currentUser: Object
  },
  methods: {
    selectJob(index){
      if(index===this.selectedJob){
        this.selectedJob = null;

      } else {
        this.selectedJob = index
      }
    },
    async deleteJob(){
      try {

        let jobId = this.list[this.selectedJob]._id

        let updatedList = await deleteAJob(this.currentUser.user._id, jobId);

        this.list = updatedList;

      } catch (e) {
        console.log(e)
      }
    },
    parsedDate(date){
      return dayjs(date).fromNow();
    },
    createNewJob(){
      this.creator = true;
    },
    async getJobList(){
      try {

        this.list = await getMyJobOffer(this.currentUser.user._id)

      } catch (e) {
        console.log(e)
      }
    },
    async createJob(){
      try {

        await createANewJob(this.currentUser.user._id, this.newJob)

        this.setModal({
          type: 'myJobOffer'
        })

      } catch (e) {
        console.log(e)
      }
    },
    ...mapActions({
      setModal: 'modal/setModal'
    })
  },
  mounted(){
    this.getJobList()
  }
}
</script>
