<template>
  <ion-page>
    <ion-content>
      <div id="container" class='sign-in'>
        <h1 class='main-title'>AXYO</h1>

          <form class='main-cont' @submit.prevent>

            <input type="email" :placeholder="$t('email')" v-model='email' />
            <input type="password" :placeholder="$t('password')" v-model='password' />
            <button @click='handleSubmit'>{{ $t('Sign in')}}</button>

            <!-- <a class='forgot' @click='forgotPass'>{{ $t('forgot password') }}</a> -->

            <!-- <a v-if='verifyEmail===true' class='forgot' @click='emailValidation'>{{ $t('resend email validation') }}</a> -->

            <button class="sign-up-link" @click='goToSign'>
              {{ $t('No account? Lets do it!') }}
            </button>

          </form>


      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { setShortToken } from '@/services/api';
import { IonPage, IonContent } from '@ionic/vue';

import { signInUser } from '@/apiCall/auth.js';

import { mapActions } from 'vuex';

export default {
  name: 'SignIn',
  data(){
    return {
      email: "",
      password: "",
    };
  },
  components: {
    IonPage,
    IonContent
  },
  computed: {
  },
  methods: {
    // resetInfo(){
    //   this.root = "";
    //   this.username = "";
    //   this.email = "";
    //   this.password = "";
    //   this.primaryAuth = true;
    //   this.arrowBackOutline = arrowBackOutline;
    //   this.useTwoAuthCode = false;
    //   this.showTwoAuthCodeInput = false;
    //   this.code = '';
    // },
    goToSign(){
      this.$router.push('/signup')
    },
    forgotPass(){
      this.$router.push('/reset-password')
    },
    emailValidation(){
      this.$router.push('/email-validation/manual/val')
    },
    async handleSubmit() {
      try {

        let { token, userInfo, location } = await signInUser({
          email: this.email,
          password: this.password
        })

        this.setUserInfo(userInfo)

        this.setLocation(location)

        localStorage.setItem('onTheSide',token)
        setShortToken(token)
        // axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;


        // this.$router.push('/home')

      } catch (e) {
        console.log(e)
      }
    },
    ...mapActions({
      setUserInfo: 'user/setUserInfo',
      setLocation: 'location/initLocation'
    })
  }
};
</script>
