<template>
  <div class="alert-teammate">
    <div class="alert-teammate-inner" :class="alert.data.status">
      <div class="teammate-cont">
        <img :src="alert.data.image" />
      </div>
      <p>{{ alert.data.message }}</p>
      <span class="close" @click="removeAlert">&times;</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "AlertRequest",
  data() {
    return {
      timer: null
    };
  },
  props: {
    alert: Object,
    removeAlert: Function
  },
  methods: {
    prepareToFade() {
      this.timer = setTimeout(() => {
        this.removeAlert();
      }, 3000);
    }
  },
  mounted() {
    this.prepareToFade();
  },
  beforeUnmount() {
    clearTimeout(this.timer);
  }
};
</script>
