<template>
  <div class="user-menu">
      <div class="inner-menu">

        <h5>{{ $t('Connected as') }} {{ currentUser.user.name || currentUser.user.firstname }}</h5>

        <FontAwesomeIcon class='close-icon' icon='times' @click='closeMenu'></FontAwesomeIcon>

        <div class="user-mode-switcher">
          <h4>{{ $t('SIDE Mode')}}</h4>
          <h5 class='user-mode' @click='switchMode("employer")' :class='{selected: currentUser.userMode==="employer"}'>{{ $t('employer')}}</h5>
          <div class="switcher" :class='{employer: currentUser.userMode==="employer"}'>
            <font-awesome-icon icon="fa-duotone fa-arrow-up-arrow-down" />
            <img v-if="currentUser.user.profileImage.small" :src="currentUser.user.profileImage.small" alt="my-picture">
            <img v-else class='user-image' src='https://images.unsplash.com/photo-1635373670332-43ea883bb081?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=200&q=80'/>
          </div>
          <h5 class='user-mode' @click='switchMode("staff")' :class='{selected: currentUser.userMode==="staff"}'>{{ $t('staff')}}</h5>
        </div>

        <ul v-if='currentUser.userMode==="employer"' class='option-list'>
          <li class='option' @click='selectUserMenu("profile")'>
            <h5>{{ $t('my profile' )}}</h5>
            <FontAwesomeIcon icon='info'></FontAwesomeIcon>
          </li>
          <!-- <li class='option' @click='selectUserMenu("membership")'>
            <h5>{{ $t('membership' )}}</h5>
            <FontAwesomeIcon icon='id-card'></FontAwesomeIcon>
          </li> -->
          <li class='option' @click='selectUserMenu("jobAndSideOffers")'>
            <h5>{{ $t('my jobs & sides offer' )}}</h5>
            <FontAwesomeIcon icon='list'></FontAwesomeIcon>
          </li>
          <!-- <li class='option' @click='selectUserMenu("payments")'>
            <h5>{{ $t('payments' )}}</h5>
            <FontAwesomeIcon icon='dollar-sign'></FontAwesomeIcon>
          </li> -->
          <!-- <li class='option' @click='selectUserMenu("employeePool")'>
            <h5>{{ $t('employee pool' )}}</h5>
            <FontAwesomeIcon icon='users'></FontAwesomeIcon>
          </li> -->
        </ul>

        <ul v-if='currentUser.userMode==="staff"' class='option-list'>
          <li class='option' @click='selectUserMenu("profile")'>
            <h5>{{ $t('my profile' )}}</h5>
            <FontAwesomeIcon icon='info'></FontAwesomeIcon>
          </li>
          <li class='option' :class='{activated: location.travelMode===true}' @click='selectUserMenu("travel")'>
            <h5>{{ $t('travel mode' )}}</h5>
            <FontAwesomeIcon icon='plane'></FontAwesomeIcon>
          </li>
          <!-- <li class='option' @click='openJobOffers'>
            <h5>{{ $t('membership' )}}</h5>
          </li> -->
          <!-- <li class='option' @click='selectUserMenu("sideList")'>
            <h5>{{ $t('my side list' )}}</h5>
            <FontAwesomeIcon icon='list'></FontAwesomeIcon>
          </li> -->
          <!-- <li class='option' @click='selectUserMenu("payments")'>
            <h5>{{ $t('payments' )}}</h5>
            <FontAwesomeIcon icon='dollar-sign'></FontAwesomeIcon>
          </li> -->
          <li class='option' @click='selectUserMenu("likeList")'>
            <h5>{{ $t('like list' )}}</h5>
            <FontAwesomeIcon icon='heart'></FontAwesomeIcon>
          </li>
        </ul>

        <div class="log-out" @click='logoutUser'>
          <FontAwesomeIcon icon='power-off'></FontAwesomeIcon>
          <h5>{{ $t('logout')}}</h5>
        </div>
      </div>


      <transition appear name="very-short" mode="out-in">
        <div v-if='selectedMenu!==null' class="user-menu-modal-cont">
          <FontAwesomeIcon class='back-icon' @click='selectedMenu=null' icon='arrow-left'></FontAwesomeIcon>
          <component :is='menu' :currentUser='currentUser' @backToMenu='selectedMenu=null' :editProfileMode='true'></component>
        </div>
      </transition>


    </div>
</template>

<script>
import { createANewJob } from '@/apiCall/job.js'

import UserProfile from '@/components/userMenu/menuModals/UserProfile.vue';
import UserPayments from '@/components/userMenu/menuModals/UserPayments.vue';
import UserSideList from '@/components/userMenu/menuModals/UserSideList.vue';
import UserLikeList from '@/components/userMenu/menuModals/UserLikeList.vue';
import UserEmployeePool from '@/components/userMenu/menuModals/UserEmployeePool.vue';
import UserMembership from '@/components/userMenu/menuModals/UserMembership.vue';
import UserJobsOffer from '@/components/userMenu/menuModals/UserJobsOffer.vue';
import UserTravelMode from '@/components/userMenu/menuModals/UserTravelMode.vue';

import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'UserMenu',
  data(){
    return {
      jobName: '',
      description: '',
      rateType: 'hourly',
      salaryRate: 15,
      //
      selectedMenu: null
    };
  },
  components: {
    UserProfile,
    UserPayments,
    UserSideList,
    UserLikeList,
    UserEmployeePool,
    UserMembership,
    UserJobsOffer,
    UserTravelMode
  },
  props: {
    currentUser: Object,
  },
  computed: {
    // isAuthenticated(){
    //   return this.currentUser.isAuthenticated
    // },
    menu(){
      let menu;
      switch (this.selectedMenu) {
        case 'profile':
          menu = 'UserProfile';
          break;

          case 'membership':
          menu = 'UserMembership';
          break;

        case 'jobAndSideOffers':
          menu = 'UserJobsOffer';
          break;

        case 'payments':
          menu = 'UserPayments';
          break;

        case 'employeePool':
          menu = 'UserEmployeePool';
          break;

        case 'likeList':
          menu = 'UserLikeList';
          break;

        case 'sideList':
          menu = 'UserSideList';
          break;

        case 'travel':
          menu = 'UserTravelMode';
          break;

      }
      return menu;
    },
    ...mapGetters({
      location: 'location/userLocation'
    })
  },
  methods: {
    logoutUser(){
      this.logOut();
      this.$router.push('/')
    },
    selectUserMenu(menu){
      this.selectedMenu = menu;
    },
    // openJobOffers(){
    //   this.setModal({
    //     type: 'myJobOffer'
    //   })
    // },
    switchMode(mode){
      this.switchUserMode(mode)
    },
    async createJob(){
      try {

        let data = this.$data;

        await createANewJob(this.currentUser.user._id,data)

      } catch (e) {
        console.log(e)
      }
    },
    ...mapActions({
      logOut: 'logOut',
      switchUserMode: 'user/switchUserMode',
      closeMenu: 'userMenu/toggleVisibility',
      setModal: 'modal/setModal'
    })
  },
  // watch: {
  //   isAuthenticated(val){
  //     if(val===true){
  //
  //     }
  //   }
  // }
}
</script>
