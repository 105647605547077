import { createStore } from 'vuex';

import { alert } from "./browser/alert";
import { modal } from "./browser/modal";
import { device } from "./browser/device";
import { user } from "./user/user";
import { userMenu } from "./user/userMenu";
import { location } from './user/location';

import { setShortToken } from '@/services/api';
import { refreshUserSession } from '@/apiCall/auth.js';

export default createStore({
  modules: {
    alert,
    device,
    modal,
    location,
    user,
    userMenu
  },
  actions: {
    async recoverSession({ dispatch }) {
      let token = localStorage.getItem("onTheSide");
      console.log('token',token)

      try {
        let { newToken, location, userInfo } = await refreshUserSession({token});

        dispatch("location/initLocation", location);

        dispatch("user/setUserInfo", userInfo);

        localStorage.setItem('onTheSide',newToken)

        setShortToken(newToken)

      } catch (e) {
        console.log(e)
        dispatch("logOut");
      }
    },
    // *********************************************************
    logOut({ dispatch }) {
    // logOut({ dispatch }) {
      // let token = localStorage.getItem("OnTheSide_token");
      // logOutUser(token);
      localStorage.clear();
      dispatch("user/logOutUser");

      // setShortToken(false);
      // dispatch("schedule/logOutUser", { root: true });
    }
  }
});
