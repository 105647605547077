<template>
  <div class="option-card" :class='{selected: selected===true}'>
    <h4 class='title'>{{ title }}</h4>
    <FontAwesomeIcon v-if='icon!==undefined' class='icon' :icon='icon'></FontAwesomeIcon>
    <p class='desc'>
      {{ desc }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'OptionCard',
  props: {
    title: {
      type: String
    },
    desc: {
      type: String
    },
    icon: {
      type: String
    },
    selected: {
      type: Boolean,
      default: false
    }
  }
}
</script>
