<template>
  <div class="user-menu-modal">
    <h3 class='menu-title'>{{ $t('Membership') }}</h3>


  </div>
</template>

<script>
export default {
  name: 'UserMembership',
  props: {
    currentUser: Object
  }
}
</script>
