<template>
  <div class="circular-uploader">
  <!-- <div class="circular-uploader" @click.stop="browseIt"> -->
    <file-pond
      ref="circularPond"
      :files="files"
      max-file="1"
      accepted-file-types="image/jpeg, image/png"
      stylePanelLayout="compact circle"
      imagePreviewHeight="190"
      imageCropAspectRatio="1"
      imageTransformOutputQuality="80"
      imageResizeTargetWidth="500"
      imageResizeTargetHeight="500"
      styleButtonActionItemPosition="center top"
      styleLoadIndicatorPosition="center bottom"
      styleButtonRemoveItemPosition="center top"
      styleButtonProcessItemPosition="right bottom"
      :labelIdle='$t("Add your profile picture here")'
      imageEditInstantEdit="true"
      v-bind:imageEditEditor="myDoka"
      imageTransformVariantsDefaultName="medium"
      :imageTransformVariants="myTransformation"
      @updatefiles="updateFiles"
      @preparefile="prepareFile"
    />
  </div>
</template>

<script>
// Import Doka
import { create } from "../../vue-doka/lib/doka.esm.min.js";
import "../../vue-doka/lib/doka.min.css";

// Import Vue FilePond
import vueFilePond from "vue-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";

// Import plugin styles
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import "filepond-plugin-image-edit/dist/filepond-plugin-image-edit.min.css";

// Import plugins
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginImageEdit from "filepond-plugin-image-edit";
import FilePondPluginImageCrop from "filepond-plugin-image-crop";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";

// Create component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImageExifOrientation,
  FilePondPluginImageCrop,
  FilePondPluginImageResize,
  FilePondPluginImagePreview,
  FilePondPluginImageEdit,
  FilePondPluginImageTransform
);

export default {
  name: "ProfileCircularUpload",
  components: {
    FilePond
  },
  props: {
    maxFile: {
      type: Number,
      default: 1
    },
    setThis: Function
  },
  data() {
    return {
      files: [],
      myTransformation: {
        xsmall: transforms => {
          transforms.resize = {
                size: {
                    width: 50,
                    height: 50,
                },
            };
          return transforms;
        },
        small: transforms => {
          transforms.resize = {
                size: {
                    width: 300,
                    height: 300,
                },
            };
          return transforms;
        },
        // medium: transforms => {
        //   transforms.resize = {
        //         size: {
        //             width: 300,
        //             height: 300,
        //         },
        //     };
        //   return transforms;
        // },
        // large: transforms => {
        //   transforms.resize = {
        //         size: {
        //             width: 800,
        //             height: 800,
        //         },
        //     };
        //   return transforms;
        // },
        // xlarge: transforms => {
        //   transforms.resize = {
        //         size: {
        //           width: 1600,
        //           height: 1600,
        //         },
        //     };
        //   return transforms;
        // },
        // tight: transforms => {
        //   transforms.resize = {
        //         size: {
        //             width: 400,
        //             height: 720,
        //         },
        //     };
        //   transforms.resize.mode = "cover";
        //   transforms.crop.aspectRatio = 2;
        //   transforms.crop.center.x = 0.5;
        //   transforms.crop.center.y = 0.5;
        //   return transforms;
        // }
      },
      myDoka: create({
        cropAspectRatioOptions: [
          {
            label: "Portrait",
            value: 1
          }
        ],
        cropMask: (root, setInnerHTML) => {
          setInnerHTML(
            root,
            `
            <mask id="my-mask">
                <rect x="0" y="0" width="100%" height="100%" fill="white"/>
                <circle cx="50%" cy="50%" r="50%" fill="black"/>
            </mask>
            <rect fill="rgba(255,255,255,.3125)" x="0" y="0" width="100%" height="100%" mask="url(#my-mask)"/>
            <circle cx="50%" cy="50%" r="50%" fill="transparent" stroke-width="1" stroke="#fff"/>
        `
          );
        }
      })
    };
  },
  methods: {
    // handleFilePondInit: function() {
    //   // FilePond instance methods are available on `this.$refs.pond`
    // },
    browseIt() {
      if (this.files.length === 0) {
        this.$refs.circularPond.browse();
      }
    },
    processFile(file) {
      console.log(file);
      this.files = [file];
    },
    prepareFile(fileItem, outputFiles) {
      // console.log("prepare", fileItem, outputFiles);
      this.$emit('pushData', {
        mainPicture: outputFiles
      });
    },
    updateFiles(fileItems) {
      console.log(fileItems);
      if (fileItems.length !== 0) {
        this.files = fileItems.map(fileItem => fileItem.file);
      } else {
        this.files = [];
        this.$emit('pushData', {
          mainPicture: []
        });
      }
    }
  }
};
</script>
