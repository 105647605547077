<template>
  <div class="vert-cont rate-staff-modal">
    <div class="rate-header">
      <FontAwesomeIcon icon='star'></FontAwesomeIcon>
      <h3>{{ $t('Review')}}</h3>
      <p>
        {{ $t('evaluate my job')}}
      </p>
    </div>

    <div class="">
      <FontAwesomeIcon @click='rate=1' icon='star'></FontAwesomeIcon>
      <FontAwesomeIcon @click='rate=2' icon='star'></FontAwesomeIcon>
      <FontAwesomeIcon @click='rate=3' icon='star'></FontAwesomeIcon>
      <FontAwesomeIcon @click='rate=4' icon='star'></FontAwesomeIcon>
      <FontAwesomeIcon @click='rate=5' icon='star'></FontAwesomeIcon>
    </div>

    <textarea v-model='comment' :placeholder="$t('Comment')">
    </textarea>

    <button class='confirm' @click='sendRate'>{{ $t('Rate!') }}</button>

  </div>
</template>

<script>

import { rateSide } from '@/apiCall/sides.js';

export default {
  name: 'RateStaff',
  props: {
    modalData: Object,
    currentUser: Object,
    setAlert: Function,
  },
  data(){
    return {
      rate: 0,
      comment: ''
    };
  },
  computed: {
    sideApplication(){
      return this.modalData.side;
    }
  },
  methods: {
    async sendRate(){
      try {

        console.log('hello')

        let data = {
          sideApplicationId: this.sideApplication._id,
          rate: this.rate,
          comment: this.comment
        }

        await rateSide(this.currentUser.user._id, data)

        this.setAlert({
          type: 'info',
          data: {
            status: 'success',
            message: this.$t('Operation successfull')
          }
        })

        this.$emit('closeModal')

      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>
