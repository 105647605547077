<template>
  <div class="user-menu-modal">

    <ul class='jobs-list' v-if='jobList.length > 0'>
      <li v-for='(job, index) in jobList' :key='index' @click='selectJob(job)'>
        <h4>{{ job.name }}</h4>
        <p class='sml-para'>
          {{ job.description }}
        </p>
        <h5 class='very-small-title'>{{ parseDate(job.createdAt) }}</h5>
      </li>
    </ul>

    <h5 v-else>{{ $t('No jobs created yet') }}</h5>

    <button class='create-btn' @click='toggleCreator'>
      <FontAwesomeIcon icon='fa-solid fa-plus'></FontAwesomeIcon>
      {{$t('job')}}
    </button>


<!-- NOTE CREATOR -->
<!-- NOTE CREATOR -->
<!-- NOTE CREATOR -->
    <transition appear name="very-short" mode="out-in">
      <div v-if='showCreator===true' class='modal'>

        <FontAwesomeIcon class='close-icon' icon='times' @click='toggleCreator'></FontAwesomeIcon>

        <h2 v-if='updateData===false'>{{ $t('Create a job')}}</h2>
        <h2 v-else>{{ $t('Update job')}}</h2>

        <form class='creation' @submit.prevent>

          <div class="line">
            <h5>{{ $t('Industry')}}</h5>
            <select v-model='jobInfo.industry'>
              <option v-for='(value, name) in industryTypesList' :value='name' :key='name'>
                {{ $t(value.name) }}
              </option>
            </select>
          </div>

          <div class="line">
            <h5>{{ $t('Language')}}</h5>
            <select v-model='jobInfo.language'>
              <option value='en'>
                en
              </option>
              <option value='fr'>
                fr
              </option>
              <option value='es'>
                es
              </option>
            </select>
          </div>

          <div class="line">
            <h5>{{ $t('Job name')}}</h5>
            <input type='text' v-model='jobInfo.name' :placeholder="$t('Job name')" />
          </div>

          <div class="line">
            <h5>{{ $t('Job description')}}</h5>
            <textarea v-model='jobInfo.description' :placeholder="$t('Description')"></textarea>
          </div>

          <div class="line">
            <h5>{{ $t('Job skills & qualifications')}}</h5>
            <textarea v-model='jobInfo.skillsQualifications' :placeholder="$t('Skills & qualifications')"></textarea>
          </div>

          <!-- <textarea v-model='jobInfo.requirements' :placeholder="$t('')"</textarea> -->

          <div class="line">
            <h5>{{ $t('Salary')}}</h5>
            <div class="hori-cont">
              <input type="number" v-model='jobInfo.salaryRate' />
              <h4>/</h4>
              <select v-model='jobInfo.rateType'>
                <option value='hourly'>
                  {{ $t('hourly')}}
                </option>
                <option value='daily'>
                  {{ $t('daily')}}
                </option>
                <option value='session'>
                  {{ $t('session')}}
                </option>
                <option value='job'>
                  {{ $t('job')}}
                </option>
              </select>
            </div>
          </div>

          <button v-if='updateData===true' class='confirm' @click='updateJob'>
            <FontAwesomeIcon icon='pen'></FontAwesomeIcon>
            {{$t('update')}}
          </button>

          <button v-else class='confirm' @click='createJob'>
            <FontAwesomeIcon icon='fa-solid fa-plus'></FontAwesomeIcon>
            {{$t('add')}}
          </button>

        </form>
      </div>
    </transition>


  </div>
</template>

<script>
import * as dayjs from 'dayjs';
let localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)

import { createANewJob, updateAJob } from '@/apiCall/job.js'

import { industryTypes } from '@/settings';

export default {
  name: 'UserMenuJobListOffer',
  data(){
    return{
      // list: [],
      // selectedJob: null,
      //
      showCreator: false,
      updateData: false,
      jobInfo: {
        _id: null,
        industry: '',
        language: '',
        name: '',
        skillsQualifications: '',
        description: '',
        rateType: 'hourly',
        salaryRate: 15,
        location: this.currentUser.user.location
      }
    };
  },
  props: {
    currentUser: Object,
    jobList: Array
  },
  computed: {
    industryTypesList(){
      return industryTypes;
    }
  },
  methods: {
    parseDate(date){
      return dayjs(date).format('LL');
    },
    toggleCreator(){
      this.showCreator = !this.showCreator;
    },
    // async fetchJob(){
    //   try {
    //
    //     this.list = await getMyJobOffer(this.currentUser.user._id)
    //
    //   } catch (e) {
    //     console.log(e)
    //   }
    // },
    async createJob(){
      try {

        /* eslint-disable */
        let { _id, ...job } = this.jobInfo;

        let data = job;

        await createANewJob(this.currentUser.user._id, data)

        this.toggleCreator()

        this.$emit('updateJobs')

      } catch (e) {
        console.log(e)
      }
    },
    async updateJob(){
      try {

        // let { _id, ...job } = this.jobInfo;

        let data = this.jobInfo;

        await updateAJob(this.currentUser.user._id, this.jobInfo._id, data)

        this.updateData = false;
        this.toggleCreator()

        this.$emit('updateJobs')

      } catch (e) {
        console.log(e)
      }
    },
    selectJob(job){
      this.jobInfo = job;
      this.updateData = true;
      this.showCreator = true;
    }
  },
  // mounted(){
  //   this.fetchJob()
  // }
}
</script>
