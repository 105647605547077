<template>
<ion-page>
  <ion-content>
    <div class="sign-up-form">
      <component @pushData='setThis' :prefLanguage='prefLanguage' :userType='userType' :editProfileMode='false' :is='compo'></component>
    </div>
  </ion-content>
</ion-page>
</template>

<script>
import {
  IonPage,
  IonContent
} from '@ionic/vue';

import AuthPrefLanguage from '@/components/auth/AuthPrefLanguage.vue';
import AuthUserType from '@/components/auth/AuthUserType.vue';
// import AuthUserForm from '@/components/auth/AuthUserForm.vue';
import UserProfile from '@/components/userMenu/menuModals/UserProfile.vue';

export default {
  name: 'SignUp',
  data() {
    return {
      stage: 1,
      userType: null,
      prefLanguage: '',
    };
  },
  components: {
    AuthPrefLanguage,
    AuthUserType,
    // AuthUserForm,
    UserProfile,
    IonPage,
    IonContent
  },
  methods: {
    setThis(data) {
      for (var x in data) {
        if ({}.hasOwnProperty.call(data, x)) {
          this[x] = data[x];
        }
      }
      this.stage += 1;
    },
  },
  computed: {
    compo() {
      let c;
      switch (this.stage) {
        case 1:
          c = 'AuthPrefLanguage';
          break;

        case 2:
          c = 'AuthUserType';
          break;

        case 3:
          c = 'UserProfile';
          // c = 'AuthUserForm';
          break;
      }
      return c;
    }
  }
}
</script>
