<template>
  <div class="account-type">
    <h1>{{ $t('Account type')}}</h1>
    <p>{{ $t('Please select which type of account you wish to create')}}</p>

    <div class="selection-options">

      <OptionCard
      @click='selectType("Business")'
      :title='$t("business")'
      :desc="$t('I am a business')"
      />

      <OptionCard
      @click='selectType("Individual")'
      :title='$t("individual")'
      :desc="$t('I am an individual')"
      />

    </div>
  </div>
</template>

<script>
import OptionCard from '@/components/genComponents/OptionCard.vue';

export default {
  name: 'AuthUserType',
  data(){
    return {
      type: null
    };
  },
  components: {
    OptionCard
  },
  methods: {
    selectType(type){
      this.$emit('pushData', {
        userType: type
      })
    }
  }
}
</script>
