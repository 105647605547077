<template>
  <div class="user-menu-modal">

    <ul class='my-side-list' v-if='sidesList.length > 0'>
      <li v-for='(side, index) in sidesList' :key='index' @click='selectSide(side)'>
        <div class="status-cont">
          <FontAwesomeIcon class='active' v-if='side.status==="active"' icon='circle'></FontAwesomeIcon>
          <FontAwesomeIcon class='active' v-if='side.status==="assigned"' icon='check'></FontAwesomeIcon>
          <FontAwesomeIcon class='inactive' v-if='side.status==="canceled"' icon='times'></FontAwesomeIcon>
          <h5>{{ side.status }}</h5>
        </div>
        <h4>{{ side.job.name }} - {{ parseDate(side.date) }}</h4>
        <p v-if='side.status!=="canceled"'>{{ side.details }}</p>
        <h4 v-if='side.status!=="canceled"'>{{ $t('Position qte')}}: {{ side.positionQte }}</h4>
        <h5 v-if='side.status!=="canceled"' class='very-small-title'>{{ parseDate(side.createdAt) }}</h5>
      </li>
    </ul>

    <h5 v-else>{{ $t('No sides created yet') }}</h5>

    <button class='create-btn' @click='toggleCreator'>
      <FontAwesomeIcon icon='fa-solid fa-plus'></FontAwesomeIcon>
      {{$t('sides')}}
    </button>

    <transition appear name="very-short" mode="out-in">
      <div v-if='showCreator===true' class='modal'>

        <FontAwesomeIcon class='close-icon' icon='times' @click='toggleCreator'></FontAwesomeIcon>

        <h2 v-if='updateData===false'>{{ $t('Create a side')}}</h2>
        <h2 v-if='updateData===true'>{{ $t('Update side')}}</h2>

        <form class='creation' @submit.prevent>

          <div class="line">
            <h5>{{ $t('Date')}}</h5>
            <input type='datetime-local' v-model='sideInfo.date' />
          </div>

          <div class="line">
            <h5>{{ $t('Select a job')}}</h5>
            <select v-model='sideInfo.jobId'>
              <option :value='null'>
                {{ $t('Select a job') }}
              </option>
              <option :value='j._id' v-for='(j, index) in jobList' :key='index'>
                {{ j.name }}
              </option>
            </select>
          </div>

          <div class="line">
            <h5>{{ $t('Details')}}</h5>
            <textarea :placeholder="$t('Details')" v-model='sideInfo.details'></textarea>
          </div>

          <button class='cancel' v-if='updateData===true&&sideInfo.status!=="canceled"' @click='cancelSide'>{{ $t('cancel') }}</button>
          <button class='confirm' v-if='updateData===true' @click='updateSide'>{{ $t('update') }}</button>
          <button class='confirm' v-if='updateData===false' @click='createSide'>{{ $t('send') }}</button>

        </form>
      </div>
    </transition>


  </div>
</template>

<script>
import * as dayjs from 'dayjs';

import { createAside, updateAside } from '@/apiCall/sides.js'

export default {
  name: 'UserMenuSidesOfferList',
  data(){
    return{
      updateData: false,
      selectedSide: null,
      //
      showCreator: false,
      sideInfo: {
        _id: null,
        date: '',
        location: '',
        jobId: null,
        details: '',
      }
    };
  },
  props: {
    currentUser: Object,
    jobList: Array,
    sidesList: Array
  },
  methods: {
    resetData(){
      this.sideInfo = {
        _id: null,
        status: 'active',
        date: '',
        location: '',
        jobId: null,
        details: '',
      }
    },
    parseDate(date){
      return dayjs(date).format('LL');
    },
    toggleCreator(){
      this.showCreator = !this.showCreator;
    },
    // async fetchSide(){
    //   try {
    //
    //     this.list = await getSideList(this.currentUser.user._id)
    //
    //   } catch (e) {
    //     console.log(e)
    //   }
    // },
    async createSide(){
      try {

        /* eslint-disable */
        let { _id, status, ...info } = this.sideInfo;

        let data = info;

        await createAside(this.currentUser.user._id,data)

        this.toggleCreator()
        this.resetData()

        this.$emit('updateSide')

      } catch (e) {
        console.log(e)
      }
    },
    cancelSide(){
      this.sideInfo.status = 'canceled';
      this.updateSide()
    },
    async updateSide(){
      try {

        let data = this.sideInfo;

        await updateAside(this.currentUser.user._id, data._id, data)

        this.resetData()
        this.updateData = false;
        this.toggleCreator()

        this.$emit('updateSide')

      } catch (e) {
        console.log(e)
      }
    },
    selectSide(side){
      if(side.status==='canceled'){
        return;
      }
      this.updateData = true;
      this.sideInfo = side;
      this.sideInfo.date = dayjs(side.date).format('YYYY-MM-DDTHH:mm');
      this.sideInfo.jobId = side.job._id;
      this.toggleCreator()
    }
  },
  mounted(){
    // this.fetchSide()
  }
}
</script>
