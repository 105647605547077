<template>
  <ion-page>
    <ion-content>
      <div class="home">

        <HomeEmployer v-if='userMode==="employer"' :currentUser='currentUser'></HomeEmployer>
        <HomeStaff v-else :currentUser='currentUser'></HomeStaff>

      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import HomeStaff from '@/components/home/HomeStaff.vue';
import HomeEmployer from '@/components/home/HomeEmployer.vue';

import { mapGetters } from 'vuex';

import { IonPage, IonContent } from '@ionic/vue';

export default {
  name: 'AppHome',
  data(){
    return {
      list: []
    };
  },
  components: {
    IonPage,
    IonContent,
    HomeStaff,
    HomeEmployer
  },
  computed: {
    userMode(){
      return this.currentUser.userMode;
    },
    ...mapGetters({
      currentUser: 'user/currentUser'
    })
  }
}
</script>
