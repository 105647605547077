<template>
  <div class="user-menu-modal user-profile-form">
    <h3 v-if="editProfileMode === true" class="menu-title">
      {{ $t("Profile") }}
    </h3>

    <div v-else class="">
      <h1>
        {{ $t("Creating a") }} {{ $t(profile.userType) }} {{ $t("account") }}
      </h1>
      <p>{{ $t("Please provide the following informations") }}</p>
    </div>

    <div
      v-if="editPicture === false"
      class="profile-img-cont"
      @click="editPicture = true"
    >
      <img
        class="user-image"
        v-if="profile.profileImage.small !== null"
        :src="profile.profileImage.small"
      />
      <img
        class="user-image"
        v-if="profile.profileImage.small === null"
        src="https://images.unsplash.com/photo-1635373670332-43ea883bb081?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=200&q=80"
      />
      <div class="edit-label">
        <FontAwesomeIcon icon="pen"></FontAwesomeIcon>
        <h5>{{ $t("Edit profile image") }}</h5>
      </div>
    </div>

    <!-- <FontAwesomeIcon class='image-placeholder' v-if="profile.profileImage.sml === null && editPicture === false" icon='user-circle'></FontAwesomeIcon> -->
    <ProfileCircularUpload
      v-if="editPicture === true"
      @pushData="setMedia"
    ></ProfileCircularUpload>

    <form class='creation' @submit.prevent autocomplete="off">

      <div class="line">
        <h5>{{ $t("Use as employer") }}</h5>
        <input
          type="checkbox"
          v-model="profile.isEmployer"
        />
      </div>

      <div class="line">
        <h5>{{ $t("Use as staff") }}</h5>
        <input
          type="checkbox"
          v-model="profile.isStaff"
        />
      </div>


      <div v-if="profile.userType === 'Business'" class="line">
        <h5>{{ $t("Business name") }}</h5>
        <input
          type="text"
          v-model="profile.name"
          :placeholder="$t('Business name')"
        />
      </div>

      <div v-if="profile.userType === 'Individual'" class="line">
        <h5>{{ $t("Firstname") }}</h5>
        <input
          type="text"
          v-model="profile.firstname"
          :placeholder="$t('Firstname')"
        />
      </div>
      <div v-if="profile.userType === 'Individual'" class="line">
        <h5>{{ $t("Lastname") }}</h5>
        <input
          type="text"
          v-model="profile.lastname"
          :placeholder="$t('Lastname')"
        />
      </div>

      <div v-if="profile.userType === 'Individual'" class="line">
        <h5>{{ $t("Date of birth") }}</h5>
        <input type="date" v-model="profile.DOB" />
      </div>

      <div class="line">
        <h5>{{ $t("About me") }}</h5>
        <textarea
          v-model="profile.aboutMe"
          :placeholder="$t('Introduce yourself')"
        />
      </div>

      <div class="line">
        <h5>{{ $t("Gender") }}</h5>
        <select
          v-if="profile.userType === 'Individual'"
          v-model="profile.gender"
        >
          <option :value="null">
            {{ $t("select a gender") }}
          </option>
          <option value="female">
            {{ $t("woman") }}
          </option>
          <option value="male">
            {{ $t("man") }}
          </option>
        </select>
      </div>

      <div class="line">
        <h5>{{ $t("Industry") }}</h5>
        <select v-model="profile.industry">
          <option :value="null">
            {{ $t("select an industry") }}
          </option>
          <option v-for="(value, name, index) in jobTypes" :value="name" :key="index">
            {{ $t(value.name) }}
          </option>
        </select>
      </div>

      <div class="line">
        <h5>{{ $t("Website") }}</h5>
        <input
          v-if="profile.userType === 'Business'"
          v-model="profile.website"
          :placeholder="$t('Website')"
        />
      </div>

      <div class="line">
        <h5>{{ $t("Phone number") }}</h5>
        <input
          type="tel"
          v-model="profile.phone"
          :placeholder="$t('Phone number')"
        />
      </div>

      <div class="line">
        <h5>{{ $t("Email") }}</h5>
        <input
          type="email"
          v-model="profile.email"
          :placeholder="$t('Email')"
        />
      </div>

      <div class="line">
        <h5>{{ $t("Languages") }}</h5>
        <div class="lng-selection-cont">
          <ul>
            <li v-for="(lng, index) in ['en', 'fr', 'es']" :key="index">
              <h5>{{ lng }}</h5>
              <input
                type="checkbox"
                :value="lng"
                v-model="profile.offeredLanguages"
              />
            </li>
          </ul>
        </div>
      </div>

      <div v-if="profile.userType === 'Individual'" class="line">
        <h5>{{ $t("Skills") }}</h5>
        <div class="add-cont">
          <input
            type="text"
            v-model="newSkill"
            :placeholder="$t('Enter a skill')"
          />
          <FontAwesomeIcon icon="plus" @click="addSkill"></FontAwesomeIcon>
        </div>

        <ul class="selection-list">
          <li v-for="(s, index) in profile.skills" :key="s">
            <FontAwesomeIcon
              icon="times"
              @click="removeSkill(index)"
            ></FontAwesomeIcon>
            <h5>{{ s }}</h5>
          </li>
        </ul>
      </div>

      <div v-if="profile.userType === 'Individual'" class="line">
        <h5>{{ $t("Education") }}</h5>
        <p>{{ $t("Formations, diploma, etc") }}...</p>
        <div class="add-cont">
          <input
            type="text"
            v-model="newEducation"
            :placeholder="$t('Enter education')"
          />
          <FontAwesomeIcon icon="plus" @click="addEducation"></FontAwesomeIcon>
        </div>

        <ul class="selection-list">
          <li v-for="(s, index) in profile.education" :key="s">
            <FontAwesomeIcon
              icon="times"
              @click="removeEducation(index)"
            ></FontAwesomeIcon>
            <h5>{{ s }}</h5>
          </li>
        </ul>
      </div>

      <div class="line">
        <h5>{{ $t("Address") }}</h5>
        <AddressAutocomplete
          v-if="profile.location === null"
          @pushData="chooseLocation"
        />
        <div v-else class="location-cont auto-width-hori-cont">
          <FontAwesomeIcon
            class="remove-icon"
            icon="times"
            @click="resetLocation"
          ></FontAwesomeIcon>
          <h5>{{ profile.location.civicLocation.label }}</h5>
        </div>
      </div>

      <button
        class="edit"
        @click="editPassword = true"
        v-if="editProfileMode === true && editPassword === false"
      >
        {{ $t("edit password") }}
      </button>

      <input
        v-if="editProfileMode === false || editPassword === true"
        type="password"
        v-model="profile.password"
        autocomplete="new-password"
        :placeholder="$t('Password')"
      />
      <input
        v-if="editProfileMode === false || editPassword === true"
        type="password"
        v-model="profile.passwordConfirmation"
        autocomplete="new-password"
        :placeholder="$t('Confirm password')"
      />

      <button
        class="cancel"
        v-if="editProfileMode === true"
        @click="backToMenu"
      >
        {{ $t("cancel changes") }}
      </button>

      <!-- NOTE CREATE BUTTON -->
      <button v-if='editProfileMode===false' class="confirm" @click="createAccount">
        {{ $t("create account") }}
      </button>
      <!-- NOTE EDIT BUTTON -->
      <button v-else class="confirm" @click="updateProfileInfo">
        {{ $t("save info") }}
      </button>
    </form>
  </div>
</template>

<script>
import { createUserAccount } from "@/apiCall/auth";
import { updateUserAccount } from "@/apiCall/profile";

import { industryTypes } from "@/settings";

import { mapActions } from "vuex";

import { multiSizePictureFormatter } from "@/services/mediaUploadFormater.js";

import AddressAutocomplete from "@/components/formComponents/AddressAutoComplete.vue";
import ProfileCircularUpload from "@/components/formComponents/ProfileCircularUpload.vue";

export default {
  name: "UserProfile",
  data() {
    return {
      editPicture: false,
      editPassword: false,
      //
      profile: {
        userType: this.userType || null,
        profileImage: {
          xsml: null,
          sml: null,
          medium: null
        },
        name: "",
        firstname: "",
        lastname: "",
        isStaff: true,
        isEmployer: false,
        gender: null,
        offeredLanguages: [],
        // prefLanguage: null,
        DOB: "",
        // civicLocation: null,
        // geoLocation: null,
        skills: [],
        education: [],
        aboutMe: "",
        location: null,
        email: "",
        phone: "",
        website: "",
        industry: null,
        password: "",
        passwordConfirmation: ""
      },
      //
      newSkill: "",
      newEducation: "",
      newPicture: null
    };
  },
  components: {
    AddressAutocomplete,
    ProfileCircularUpload
  },
  props: {
    userType: String,
    prefLanguage: String,
    currentUser: Object,
    editProfileMode: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    jobTypes() {
      return industryTypes;
    }
  },
  methods: {
    addEducation() {
      this.profile.education.push(this.newEducation);
      this.newEducation = "";
    },
    removeEducation(index) {
      this.profile.education.splice(index, 1);
    },
    addSkill() {
      this.profile.skills.push(this.newSkill);
      this.newSkill = "";
    },
    removeSkill(index) {
      this.profile.skills.splice(index, 1);
    },
    backToMenu() {
      this.$emit("backToMenu");
    },
    setMedia(media) {
      this.newPicture = media.mainPicture;
      console.log(media);
    },
    setProfileInfo() {
      // let { profileImage, ...profile} = this.currentUser.user
      let existingProfile = Object.assign(
        {},
        this.profile,
        this.currentUser.user
      );
      this.profile = existingProfile;
      // this.profile = this.currentUser.user;
      // this.profile = profile;
    },
    resetLocation() {
      this.location = {};
    },
    chooseLocation(loc) {
      console.log(loc);
      let { longitude, latitude, ...remainingInfo } = loc;

      let geoLocation = {
        coordinates: [longitude, latitude],
        type: "Point"
      };

      let civicLocation = {
        ...remainingInfo
      };

      this.profile.location = {
        geoLocation,
        civicLocation
      }
    },
    async createAccount() {
      try {
        if (this.password !== this.passwordConfirmation) {
          alert(this.$t("password error"));
          return;
        }


        /* eslint-disable no-unused-vars */
        let profileData = {
          data: {
            userType: this.userType,
            prefLanguage: this.prefLanguage,
            ...this.profile
          },
          filesInfo: null
        };


        let files = null;
        if (this.newPicture !== null) {
          let formattedPic = await multiSizePictureFormatter(this.newPicture);
          profileData.filesInfo = formattedPic.info;
          files = formattedPic.files;
        }


        let { token, userInfo, location } = await createUserAccount(profileData, files);

        this.setUserInfo(userInfo);

        this.setLocation(location)

        localStorage.setItem("onTheSide", token);

        this.$router.push("/home");
      } catch (e) {
        console.log(e);
      }
    },
    async updateProfileInfo() {
      try {
        /* eslint-disable no-unused-vars */
        let {
          profileImage,
          password,
          passwordConfirmation,
          createdAt,
          _id,
          __v,
          updatedAt,
          ...otherData
        } = this.profile;

        let profileData = {
          data: otherData,
          filesInfo: null
        };

        if (this.editPassword === true) {
          profileData.data.password = password;
          profileData.data.passwordConfirmation = passwordConfirmation;
        }

        let files = null;
        if (this.newPicture !== null) {
          let formattedPic = await multiSizePictureFormatter(this.newPicture);
          profileData.filesInfo = formattedPic.info;
          files = formattedPic.files;
        }

        // let { token, userInfo } = await createUserAccount(profileData, files)
        let userInfo = await updateUserAccount(
          this.currentUser.user._id,
          profileData,
          files
        );

        this.setUserInfo(userInfo);
      } catch (e) {
        console.log(e);
      }
    },
    ...mapActions({
      setUserInfo: "user/setUserInfo",
      setLocation: 'location/initLocation'
    })
  },
  mounted() {
    if (this.editProfileMode === true) {
      this.setProfileInfo();
    }
  }
};
</script>
