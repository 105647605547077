<template>
  <transition appear name="very-short" mode="out-in">
    <div v-if='modal.type!==null' class="modal">
      <FontAwesomeIcon class='close-icon' icon='times' @click='closeModal'></FontAwesomeIcon>
      <component :is='comp' :setAlert='setAlert' :modalData='modal.data' :currentUser='currentUser' @closeModal='closeModal'></component>
    </div>
  </transition>
</template>


<script>
import { mapGetters, mapActions } from 'vuex';

import UserMenu from '@/components/userMenu/UserMenu.vue';
import UserProfileModal from '@/components/modal/type/UserProfileModal.vue';
import SideVisualizer from '@/components/modal/type/SideVisualizer.vue';
import MyJobs from '@/components/jobs/MyJobs.vue';
import MyJobOffer from '@/components/jobs/MyJobOffer.vue';
import RateStaff from '@/components/modal/type/RateStaff.vue';

export default {
  name: 'AppModal',
  // data(){
  //   return {
  //
  //   };
  // },
  components: {
    UserProfileModal,
    UserMenu,
    MyJobs,
    MyJobOffer,
    RateStaff
  },
  methods: {
    ...mapActions({
      setAlert: 'alert/setAlert',
      closeModal: 'modal/resetModal'
    })
  },
  computed: {
    comp(){
      let c;
      switch (this.modal.type) {
        case 'userMenu':
          c = UserMenu;
          break;

        case 'myJobs':
          c = MyJobs;
          break;

        case 'myJobOffer':
          c = MyJobOffer;
          break;

        case 'userProfile':
          c = UserProfileModal;
          break;

        case 'sideVisualizer':
          c = SideVisualizer;
          break;

        case 'rateStaff':
          c = RateStaff;
          break;

      }
      return c;
    },
    ...mapGetters({
      currentUser: 'user/currentUser',
      modal: 'modal/getModal'
    })
  }
}
</script>
