<template>
  <ion-page>
  <ion-content>
    <div class="search-page">
      <h3>{{ $t('Search for content')}}</h3>
      <div class="input-cont">
        <input v-model='search' :placeholder="$t('Search job, business, staff')" />
        <FontAwesomeIcon v-if='search.length > 0' class='times' icon='times' @click='resetSearch'></FontAwesomeIcon>
      </div>

      <ul class='res-list user-res-list' v-if='list.user.length > 0'>
        <li @click='goToUser(res)' v-for='(res, index) in list.user' :key='index'>
          <div class="user-label">
            <img :src='res.profileImage.small' />
            <h5>{{ res.name || `${res.firstname} ${res.lastname}` }}</h5>
          </div>
          <div class="line">
            <h5>{{ res.prefLanguage }}</h5>
            <h5>{{ res.industry }}</h5>
          </div>

          <h5 class='sml-para'>{{ `${res.location.civicLocation.city}, ${res.location.civicLocation.stateCode}` }}</h5>
        </li>
      </ul>

      <ul class='res-list job-res-list' v-if='list.job.length > 0'>
        <li @click='goToSide(res)' v-for='(res, index) in list.job' :key='index'>
          <div class="job-header">
            <FontAwesomeIcon icon='briefcase'></FontAwesomeIcon>
            <h5>Side</h5>
          </div>
          <div class="line">
            <h4 class='job-title'>{{ res.job.name }}</h4>
            <h5>{{ res.job.industry }}</h5>
          </div>

          <h5 class='sml-para'>{{ res.job.location.civicLocation.label }}</h5>

          <div class="line">
            <div class="user-label">
              <img :src='res.employerId.profileImage.small' />
              <h5>{{ res.employerId.name || `${res.employerId.firstname} ${res.employerId.lastname}` }}</h5>
            </div>
            <h5 class='sml-para'>{{ parseDate(res.date) }}</h5>
          </div>
        </li>
      </ul>

      <h5 v-if='list.job.length===0&&list.user.length===0'>{{ $t('No results') }}</h5>

    </div>
  </ion-content>
</ion-page>
</template>

<script>
import { IonPage, IonContent } from '@ionic/vue';

import * as dayjs from 'dayjs';

import { searchForSidesByKeywords } from '@/apiCall/data.js';

import {
  mapGetters,
  mapActions
 } from 'vuex';

export default {
  name: 'AppSearch',
  data(){
    return {
      list: {
        user: [],
        job: []
      },
      search: ''
    };
  },
  components: {
    IonPage,
    IonContent
  },
  computed: {
    ...mapGetters({
      location: 'location/userLocation',
      currentUser: 'user/currentUser'
    })
  },
  methods: {
    resetSearch(){
      this.search = '',
      this.list = {
        user: [],
        job: []
      }
    },
    parseDate(date){
      return dayjs(date).format('LLLL');
    },
    goToSide(side){
      this.setModal({
        type: 'sideVisualizer',
        side: side
      })
    },
    goToUser(user){
      this.setModal({
        type: 'userProfile',
        user: user
      })
    },
    async submitSearch(){
      try {

        let { latitude, longitude } = this.location.data;

        this.list = await searchForSidesByKeywords(this.currentUser.user._id, this.search, latitude, longitude)

      } catch (e) {
        console.log(e)
      }
    },
    ...mapActions({
      setModal: 'modal/setModal'
    })
  },
  watch: {
    search(val){
      if(val.length > 3){
        this.submitSearch()
      }
    }
  }
}
</script>
