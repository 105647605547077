<template>
  <div class="alert-option">
    <h1 class="big-title-med">{{ alert.data.message }}</h1>
    <ul class="alert-option-btn-cont">
      <button class="alert-btn-true" @click="selectOption(true)">
        {{ alert.data.options.true }}
      </button>
      <button class="alert-btn-false" @click="selectOption(false)">
        {{ alert.data.options.false }}
      </button>
    </ul>
  </div>
</template>

<script>
export default {
  name: "AlertTrueOrFalse",
  props: {
    alert: Object,
    removeAlert: Function
  },
  methods: {
    selectOption(option) {
      if (option === true) {
        this.alert.data.cb();
      }
      this.removeAlert();
    }
  }
};
</script>
