<template>
  <div class="navbar" :class='[userMode,{auth: currentUser.isAuthenticated===true }]'>
    <img @click='toRoot' class='logo' src='https://onthesideapp.s3.amazonaws.com/ressources/logo/Group+1342.png' />
    <div v-if='currentUser.isAuthenticated===false' class="unsigned-bar">
      <h5 @click='navTo("/signin")'>{{ $t('sign in')}}</h5>
      <h5 @click='navTo("/signup")'>{{ $t('sign up')}}</h5>
    </div>

  </div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'AppNavbar',
  data(){
    return {
      showMenu: false
    };
  },
  methods: {
    toRoot(){
      if(this.currentUser.isAuthenticated===true){
        return;
      } else {
        this.$router.push('/')
      }
    },
    navTo(to){
      this.$router.push(to)
    },
    goTo(to){
      this.$router.push(to)
    },
    logoutUser(){
      this.logOutUser();
      this.$router.push('/')
    },
    ...mapActions({
      logOutUser: 'user/logOutUser',
      setModal: 'modal/setModal'
    })
  },
  computed: {
    userMode(){
      return this.currentUser.userMode;
    },
    ...mapGetters({
      currentUser: 'user/currentUser'
    })
  }
}
</script>
