const DEFAUT_STATE = {
  plateform: null,
  isMobile: null,
  deviceType: null,
  screenType: 'small', // NOTE OPTION ['small', 'medium','large', 'xLarge']
  contentSize: 'mobile', // NOTE OPTION ['mobile', 'wideScreen']
  deviceSize: {
    width: 0,
    height: 0
  },
};

export const device = {
  namespaced: true,
  state() {
    return DEFAUT_STATE;
  },
  getters: {
    getDeviceInfo(state) {
      return state;
    },
    getPlateform(state) {
      return state.plateform;
    },
    getDeviceType(state) {
      return state.deviceType;
    },
    getDeviceSize(state) {
      return state.deviceSize;
    }
  },
  mutations: {
    SET_PLATEFORME(state, data) {
      state.plateform = data.plateform;
      state.isMobile = data.isMobile;
      state.deviceType = data.deviceType;
      state.deviceSize = data.deviceSize;
      state.contentSize = data.contentSize;
      state.screenType = data.screenType;
    }
  },
  actions: {
    setPlateform({ commit }, data) {
      commit("SET_PLATEFORME", data);
    },
  }
};
