<template>
  <div class="user-menu-modal">
    <h3 class='menu-title'>{{ $t('Like list') }}</h3>

    <ul>
      <li @click='goToUser(user)' v-for='(user, index) in loveList' :key='index'>
        <div class="user-label">
          <img :src='user.profileImage.small' />
          <h5>{{ user.name || `${user.firstname} ${user.lastname}` }}</h5>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>

import { mapActions } from 'vuex';

export default {
  name: 'UserLikeList',
  props: {
    currentUser: Object

  },
  computed: {
    loveList(){
      return [...this.currentUser.user.favStaffList, ...this.currentUser.user.favEmployerList];
    }
  },
  methods: {
    goToUser(user){
      this.setModal({
        type: 'userProfile',
        user: user
      })
    },
    ...mapActions({
      setModal: 'modal/setModal'
    })
  }
}
</script>
