import english from "./en.json";
import french from "./fr.json";
import spanish from "./es.json";


export const messages = {
  en: english,
  fr: french,
  es: spanish
};
