<template>
  <ul v-if='list.length > 0' class="hori-list home-staff-list">
    <li class='user-profile-card' @click='selectStaff(staff)' v-for='(staff, index) in list' :key='index'>
      <div class="line header">
        <img v-if='staff.profileImage.small' :src='staff.profileImage.small' />
        <h4 class='name'>{{ `${staff.firstname} ${staff.lastname.substring(0,1)}.` || name }}</h4>
        <div class="score-review">
          <h5>4</h5>
          <FontAwesomeIcon icon='star'></FontAwesomeIcon>
        </div>
      </div>
      <div class="info-cont">
        <ul class='preview-list'>
          <li v-for='(skill, index) in staff.skills' :key='index'>
            <h5>{{ skill }}</h5>
          </li>
        </ul>
        <!-- <p>
          {{ staff.description }}
        </p> -->
      </div>
    </li>
  </ul>

  <h5 v-else class='sml-para'>{{ $t('no content') }}</h5>
</template>

<script>
import { getStaffList } from '@/apiCall/profile.js';

import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'StaffList',
  data(){
    return {
      list: [],
    };
  },
  props: {
    currentUser: Object,
    listType: String,
  },
  computed: {
    ...mapGetters({
      location: 'location/userLocation'
    })
  },
  methods: {
    selectStaff(staff){
      this.setModal({
        type: 'userProfile',
        user: staff
      })
    },
    async fetchStaffList(){
      try {

        let { latitude, longitude } = this.location.data;
        let data = {
          lat: latitude,
          long: longitude
        }
        let list = [];
        switch (this.listType) {
          case 'near':
          data.type = 'near';
          list = await getStaffList(this.currentUser.user._id, data)
          break;
        }

        this.list = list;

      } catch (e) {
        console.log(e)
      }
    },
    ...mapActions({
      setModal: 'modal/setModal'
    })
  },
  mounted(){
    if(this.location.loaded===true){
      this.fetchStaffList()
    }
  }
}
</script>
