<template>
  <div class="user-menu-modal job-and-side-menu">

    <MenuHeader @menuSelection='selectPage' :selected='selectedPage' :title="$t('Jobs & Sides')" :menuOptions="['Jobs list', 'Sides list', 'Completed sides']"></MenuHeader>

    <transition appear name="very-short" mode="out-in">
      <component :is='page' @updateJobs='fetchJob' @updateSide='fetchSides' :jobList='jobList' :sidesList='sidesList' :currentUser='currentUser'></component>
    </transition>


  </div>
</template>

<script>
import MenuHeader from '@/components/genComponents/MenuHeader.vue';
import UserMenuJobListOffer from './jobOffers/UserMenuJobListOffer.vue';
import UserMenuSidesOfferList from './jobOffers/UserMenuSidesOfferList.vue';
import UserMenuCompletedList from './jobOffers/UserMenuCompletedList.vue';

import { getMyJobOffer } from '@/apiCall/job.js'
import { getSideList } from '@/apiCall/sides.js'

export default {
  name: 'UserJobsOffer',
  data(){
    return {
      selectedPage: 'Jobs list',
      jobList: [],
      sidesList: []
    };
  },
  components: {
    MenuHeader,
    UserMenuJobListOffer,
    UserMenuSidesOfferList,
    UserMenuCompletedList
  },
  props: {
    currentUser: Object
  },
  computed: {
    // menuOptions(){
    //   return [{
    //     opt
    //   }];
    // },
    page(){
      let page;
      switch (this.selectedPage) {
        case 'Jobs list':
          page = UserMenuJobListOffer
          break;

        case 'Sides list':
          page = UserMenuSidesOfferList
          break;

        case 'Completed sides':
          page = UserMenuCompletedList
          break;
      }
      return page;
    }
  },
  methods: {
  async fetchSides(){
    try {

      this.sidesList = await getSideList(this.currentUser.user._id)

    } catch (e) {
      console.log(e)
    }
  },
  async fetchJob(){
    try {

      this.jobList = await getMyJobOffer(this.currentUser.user._id)

    } catch (e) {
      console.log(e)
    }
  },
    selectPage(page){
      this.selectedPage = page;
    }
  },
  mounted(){
    this.fetchJob()
    this.fetchSides()
  }
}
</script>
