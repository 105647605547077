<template>
  <div class="menu-header">
    <h3>{{ title }}</h3>
    <ul>
      <li :class='{selected: selected===opt}' @click='select(opt)' v-for='(opt, index) in menuOptions' :key='index'>
        <h5>{{ $t(opt) }}</h5>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'MenuHeader',
  props: {
    title: {
      type: String,
    },
    selected: {
      type: String
    },
    menuOptions: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  methods: {
    select(menu){
      this.$emit('menuSelection', menu)
    }
  }
}
</script>
