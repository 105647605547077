import { getUserInfo } from '@/apiCall/profile.js'

const DEFAULT_STATE = {
  isAuthenticated: false,
  userMode: 'staff',
  user: {}
};

export const user = {
  namespaced: true,
  state() {
    return DEFAULT_STATE;
  },
  getters: {
    currentUser(state) {
      return state;
    },
    isAuthenticated(state) {
      return state.isAuthenticated;
    }
  },
  mutations: {
    SET_CURRENT_USER(state, user) {
      state.isAuthenticated = true;
      state.user = user;
    },
    SWITCH_USER_MODE(state, mode) {
      state.userMode = mode;
    },
    CLEAR_STATE(state) {
      state.isAuthenticated = false;
      state.user = {};
    }
  },
  actions: {
    setUserInfo({ commit }, info) {
      commit("SET_CURRENT_USER", info);
    },
    async updateUserInfo({ commit }, userId) {
      try {
        let info = await getUserInfo(userId)

        commit("SET_CURRENT_USER", info);
      } catch (e) {
        console.log(e)
      }
    },
    switchUserMode({ commit }, mode) {
      commit("SWITCH_USER_MODE", mode);
    },
    logOutUser({ commit }) {
      commit("CLEAR_STATE");
    }
  }
};
