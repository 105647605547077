<template>
    <ul class='vert-list'>
      <li class='vert-list align-start'>
        <h3 class='med-title'>{{ $t('Near')}}</h3>
        <StaffList @select='selectStaff' :currentUser='currentUser' listType='near'></StaffList>
      </li>

      <!-- <li class="list">
        <h3>{{ $t('new')}}</h3>
        <StaffList @select='selectStaff' :currentUser='currentUser' listType='new'></StaffList>
      </li> -->
    </ul>
</template>

<script>
import StaffList from '@/components/list/StaffList.vue';


export default {
  name: 'HomeEmployer',
  data(){
    return {
      selectedStaff: null
    };
  },
  components: {
    StaffList
  },
  props: {
    currentUser: Object
  },
}
</script>
