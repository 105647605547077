import { Geolocation } from '@capacitor/geolocation';



// bound user coordinates to maximise autocomplete results
export const nativeGeoLocate = async () => {
  try {

    const options = {
      enableHighAccuracy: true,
      // timeout: 10000, // before error is called
      // maximumAge: 1800000 // cached to 30 min max life
    };

    const coordinates = await Geolocation.getCurrentPosition(options);
    console.log(coordinates);

    const { latitude, longitude } = coordinates.coords;
    const coords = {
      latitude,
      longitude
    };

    return coords;

  } catch (e) {
      console.log(e);
      throw new Error("geo location failed");
  }
};




const getPosition = async options => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject, options);
  });
};

export const getMyPosition = async () => {
  try {
    if (!navigator.geolocation) {
      alert(
        "Your navigator does not support Geolocation, please enter the 21st century"
      );
      return { coords: "blocked", locationMode: "STD" };
    }

    const options = {
      enableHighAccuracy: true,
      timeout: 10000, // before error is called
      maximumAge: 1800000 // cached to 30 min max life
    };

    let position = await getPosition(options);

    const { latitude, longitude } = position.coords;

    const coords = {
      latitude,
      longitude
    };

    return coords;
  } catch (err) {
    console.log(err);
    throw new Error("geo location failed");
  }
};

export const getTravelPosition = loc => {
  const data = {
    coords: {
      latitude: loc.lat,
      longitude: loc.long
    },
    locationMode: "TRAVEL"
  };

  return data;
};

//
// let setNewLocation;
//
// const locationTrackingError = () => {
//   alert('Location Tracking failed');
//   throw('GeoWatch error');
// }
//
// if(this.props.mode==="STD"){
//   // if position mode set to standard, update periodically position
//   setNewLocation = position => {
//     // if position is unchanged dont update it
//     if(this.props.location.loaded===true&&this.props.location.latitude===position.coords.latitude){
//       return;
//
//     } else {
//       // if position different update it
//       this.props.getLocation();
//     }
//   }
//
// } else {
//   // if in travel MODE disable position update
//   setNewLocation = () => {
//     return;
//   }
// }
//
// // navigator.geolocation.watchPosition(setNewLocation, locationTrackingError);
// // watcher = navigator.geolocation.watchPosition(setNewLocation, locationTrackingError);
