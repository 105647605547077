<template>
  <div>
    <h2>{{ $t('my jobs')}}</h2>

    <button @click='goTo("/newjob")'>
      <FontAwesomeIcon icon='plus'></FontAwesomeIcon>
      {{ $t('create job')}}
    </button>

    <ul v-if='list.length > 0'>
      <li v-for='(l, index) in list' :key='index'>
        <h5>list item</h5>
      </li>
    </ul>

    <h5>{{ $t('no jobs created yet')}}</h5>

  </div>
</template>

<script>
export default {
  name: 'MyJobs',
  data(){
    return {
      list: []
    };
  }
}
</script>
