import { createApp } from 'vue'
import App from './App.vue'
import router from './router';

import store from './store';

import { IonicVue } from '@ionic/vue';

import { library } from "@fortawesome/fontawesome-svg-core";
import { fass } from '@fortawesome/sharp-solid-svg-icons'
import { fas } from "@fortawesome/pro-solid-svg-icons";
import { far } from "@fortawesome/pro-regular-svg-icons";
import { fal } from "@fortawesome/pro-light-svg-icons";
import { fad } from "@fortawesome/pro-duotone-svg-icons";
import { fat } from "@fortawesome/pro-thin-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

import i18n from './i18n'

const app = createApp(App).use(i18n).use(i18n).use(i18n)
  .use(store)
  .use(i18n)
  .use(IonicVue)
  .use(router);

app.component("FontAwesomeIcon", FontAwesomeIcon);

library.add(fas, fass, far, fab, fal, fat, fad);

router.isReady().then(() => {
  app.mount('#app');
});
