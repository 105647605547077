import { apiCall } from "../services/api";



export const createAside = async (userId, data) => {
  return apiCall("post", `/api/user/${userId}/side`, data);
};

export const updateAside = async (userId, sideId, data) => {
  return apiCall("put", `/api/user/${userId}/side/${sideId}`, data);
};

export const getSideList = async (userId) => {
  return apiCall("get", `/api/user/${userId}/side/list`);
};

export const getEmployerSideApplicationList = async (userId, status) => {
  return apiCall("get", `/api/user/${userId}/side/employer-application?status=${status}`);
};

export const getStaffSideApplicationList = async (userId, status) => {
  return apiCall("get", `/api/user/${userId}/side/staff-application?status=${status}`);
};


export const hireSideProspect = async (userId, data) => {
  return apiCall("put", `/api/user/${userId}/side/hire-application`, data);
};

export const rejectSideProspect = async (userId, data) => {
  return apiCall("put", `/api/user/${userId}/side/reject-application`, data);
};

export const cancelSideProspect = async (userId, data) => {
  return apiCall("put", `/api/user/${userId}/side/cancel-application`, data);
};


export const applyToSide = async (userId, sideId, data) => {
  return apiCall("put", `/api/user/${userId}/side/${sideId}/apply`, data);
};

export const hasApplied = async (userId, sideId) => {
  return apiCall("get", `/api/user/${userId}/side/${sideId}/has-applied`);
};

export const fetchSideList = async (userId, data) => {
  let { radius, lat, long, type, cat=['all'] } = data;
  return await apiCall("get", `/api/user/${userId}/side/search?lat=${lat}&long=${long}&type=${type}&cat[]=${cat}&radius=${radius}`);
};

// export const getMyJobOffer = async (userId) => {
//   return apiCall("get", `/api/user/${userId}/job/list`);
// };

export const updateSide = async (userId, jobId, data) => {
  return apiCall("put", `/api/user/${userId}/side/${jobId}`, data);
};

export const cancelSideApplication = async (userId, sideAppId) => {
  return apiCall("put", `/api/user/${userId}/side/cancel/${sideAppId}`);
};

export const deleteSide = async (userId, jobId) => {
  return apiCall("delete", `/api/user/${userId}/side/${jobId}`);
};

export const rateSide = async (userId, data) => {
  return apiCall("post", `/api/user/${userId}/side/rate`, data);
};
