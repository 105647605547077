import { apiCall } from "../services/api.js";

export const createANewJob = async (userId, data) => {
  return apiCall("post", `/api/user/${userId}/job`, data);
};

export const getMyJobOffer = async (userId) => {
  return apiCall("get", `/api/user/${userId}/job/list`);
};



export const updateAJob = async (userId, jobId, data) => {
  return apiCall("put", `/api/user/${userId}/job/${jobId}`, data);
};

export const deleteAJob = async (userId, jobId) => {
  return apiCall("delete", `/api/user/${userId}/job/${jobId}`);
};
