const DEFAUT_STATE = {
  show: false
};

export const userMenu = {
  namespaced: true,
  state() {
    return DEFAUT_STATE;
  },
  getters: {
    getUserMenu(state) {
      return state;
    }
  },
  mutations: {
    TOGGLE_MENU_VIS(state) {
      state.show = !state.show;
    }
  },
  actions: {
    toggleVisibility({ commit }) {
      commit("TOGGLE_MENU_VIS");
    }
  }
};
